import React, { useState, useEffect, useRef } from 'react';

import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';
import Styles, { Fab, MiniFab, LineMenuHome, primary_color } from '../Styles'

import {
    api_bd
} from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import clientDetails from '../../atoms/client_details.atom'
import navigationState from '../../atoms/navigate_state.atom'

import '../Navbar.css';

import LogoColor from '../../assets/logo_big_intec.png'

import Modal from '../Modal'

import FormDataUser from '../Forms/FormDataUser'

import FormDataClient from '../Forms/FormDataClient';

import ListPrefixes from '../ListPrefixes';

const { backend_files_address } = require('../../config.json')

function LeftSideBar(props) {
    const styles = Styles();

    // const navigation = useRecoilValue(navigationState)
    const [navigation, set_navigation] = useRecoilState(navigationState)

    const sgi_user = useRecoilValue(userState)

    const profile_data = useRecoilValue(profileData)

    const set_client_details = useSetRecoilState(clientDetails)

    const [data_home, set_data_home] = useRecoilState(customerData)

    const [glpi_level, set_glpi_level] = useState('')

    // const [show_modal, set_show_modal] = useState(false)

    // const [show_modal_cad_client, set_show_modal_cad_client] = useState(false)

    const [show_loading, set_show_loading] = useState(true)

    const [device_height, set_device_height] = useState(null)

    const [device_width, set_device_width] = useState(null)

    const [show_install_button, set_show_install_button] = useState(false)

    const [deferredPrompt, set_deferredPrompt] = useState(null)

    const [showModal, setShowModal] = useState(false)

    const [contentModal, setContentModal] = useState(null)

    const menu_lateral_ref = useRef(null);

    const [sideBarData, setsideBarData] = useState({
        logoUrl: "",
        userName: "",
        showEditButtom: null,
        actualView: null
    })

    const set_show_menu_left = () => {
        menu_lateral_ref.current.classList.toggle('menu_close')
    }

    const loadCustomers = async () => {
        const dados = await api_bd.get(`/customers`)
        if (dados.data.length > 0) {
            const ordered_data = dados.data //? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
            // set_data_home(dados.data)
            set_data_home(ordered_data)
        }
    }

    useEffect(() => {
        set_client_details({})
        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight

        set_device_width(dw)
        set_device_height(dh)

        // let deferredPrompt; // Allows to show the install prompt
        // let setupButton;
        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later.
            set_deferredPrompt(e);
            console.log("beforeinstallprompt fired");

            set_show_install_button(true)
        });

        return () => {
            window.removeEventListener('beforeinstallprompt', (e) => {
                console.log("beforeinstallprompt listner cleaned up");
            });
        };

    }, [])


    useEffect(() => {
        set_glpi_level(profile_data.nivel)
    }, [sgi_user])


    useEffect(() => {
        (async () => {
            set_show_loading(true)
            if (glpi_level.match(/Cliente/)) {
                let dados = await api_bd.get(`/customers/glpiuser/${profile_data.username}`)
                set_show_loading(false)
                if (dados.data.length > 0) {
                    set_data_home(dados.data)
                }
            } else if (glpi_level.match(/Técnico/i) || glpi_level === 'Super') {
                let dados = await api_bd.get(`/customers`)
                if (dados.data.length > 0) {
                    let ordered_data = dados?.data// ? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
                    // set_data_home(dados.data)
                    set_show_loading(false)
                    set_data_home(ordered_data)
                }
            }


        })()
    }, [glpi_level])


    useEffect(() => {
        if (Object.keys(navigation).length > 0) {
            if (navigation.hasOwnProperty('page_content')) {
                switch (navigation.page_content) {
                    case 'customer_detail':
                        setsideBarData(acData => {
                            return ({
                                ...acData,
                                logoUrl: `${backend_files_address}/${navigation.page_content_data.logomarca.filename}`,
                                userName: navigation.page_content_data.nome_fantasia,
                                actualView: navigation.page_content
                            })
                        })
                        break;

                    case 'home':
                        setsideBarData(acData => {
                            return ({
                                ...acData,
                                logoUrl: (profile_data && profile_data.imagem != '') ? `${backend_files_address}/${profile_data.imagem}` : LogoColor,
                                userName: (profile_data && profile_data.nome != '') ? profile_data.nome : '',
                                actualView: navigation.page_content
                            })
                        })
                        break;

                    case 'listtickets':
                        setsideBarData(acData => {
                            return ({
                                ...acData,
                                logoUrl: (profile_data && profile_data.imagem != '') ? `${backend_files_address}/${profile_data.imagem}` : LogoColor,
                                userName: (profile_data && profile_data.nome != '') ? profile_data.nome : '',
                                actualView: navigation.page_content
                            })
                        })
                        break;

                    case 'ticket_detail':
                        setsideBarData(acData => {
                            return ({
                                ...acData,
                                logoUrl: (profile_data && profile_data.imagem != '') ? `${backend_files_address}/${profile_data.imagem}` : LogoColor,
                                userName: (profile_data && profile_data.nome != '') ? profile_data.nome : '',
                                actualView: navigation.page_content
                            })
                        })
                        break;

                    case 'formtickets':
                        setsideBarData(acData => {
                            return ({
                                ...acData,
                                logoUrl: (profile_data && profile_data.imagem != '') ? `${backend_files_address}/${profile_data.imagem}` : LogoColor,
                                userName: (profile_data && profile_data.nome != '') ? profile_data.nome : '',
                                actualView: navigation.page_content
                            })
                        })
                        break;

                    default:
                        break;
                }
            }
        }

    }, [navigation])


    useEffect(() => {
        if (contentModal === null) {
            setShowModal(false)
        } else {
            setShowModal(true)
        }
    }, [contentModal])


    useEffect(()=>{
        setsideBarData(acData => {
            return ({
                ...acData,
                logoUrl: (profile_data && profile_data.imagem != '') ? `${backend_files_address}/${profile_data.imagem}` : LogoColor,
                userName: (profile_data && profile_data.nome != '') ? profile_data.nome : '',
                actualView: navigation.page_content
            })
        })
    },[profile_data])



    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                // marginLeft: position_left_side_bar,
                top: 0,
                minWidth: 210,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
                overflowY: "auto"
            }}
            ref={menu_lateral_ref}
            className="height_less_header menu_open"
        >
            {/* {device_width <= 1220 ? show_menu_left ? 'menu_open' : 'menu_close' : ''} */}
            <div
                style={{ top: 12, left: 10, position: 'absolute' }}
                // className=" ticketsTableMin"
                onClick={() => {
                    // set_show_menu_left(!show_menu_left)
                    set_show_menu_left()
                }}
            >
                <span style={{ cursor: 'pointer', color: '#fff', fontSize: '2.3em' }} className="material-icons">menu</span>
            </div>
            <div
                className="height_less_header flex flexRow"
                style={{
                    minWidth: 210,
                    width: 210,
                    maxWidth: 210,
                    alignItems: "flex-start"
                }}>


                {/* {show_modal &&
                    <Modal callbackfn={set_show_modal}>
                        <FormDataUser
                            // width={device_width < device_height ? "80%" : "50%"}
                            edit={profile_data}
                            avatar={profile_data.nivel.match(/Cliente/) ? `${backend_files_address}/${data_home[0].logomarca.filename}` : false}
                            callback={(_newData) => {
                                // console.log(_newData)
                                // set_user_sgi(_newData)
                                set_show_modal(false)
                            }} />
                    </Modal>
                } */}


                {showModal &&
                    <Modal callbackfn={() => {
                        setContentModal(null)
                    }}>
                        {contentModal}
                    </Modal>
                }


                {/* {show_modal_cad_client &&
                    <Modal callbackfn={() => {
                        set_show_modal_cad_client(false)
                    }}>
                        <FormDataClient
                            updateData={loadCustomers}
                            callbackfn={() => {
                                set_show_modal_cad_client(false)
                            }} />
                    </Modal>
                } */}

                <>
                    {/* {data_home.length > 0 && */}
                    <div
                        style={{
                            backgroundColor: "#3c5ca6",
                            color: "white"
                        }}
                        className="height_less_header"
                    >

                        <div style={{ margin: 35, overflow: "hidden", width: 140, height: 140, backgroundColor: '#fff', borderRadius: '50%', border: '3px solid lightgray' }} className="flex center-h">
                            <img
                                style={{ marginLeft: 0, marginTop: 0, width: 140, height: 140, objectFit: "cover" }}
                                // src={(profile_data && profile_data.imagem != '') ? `${backend_files_address}/${profile_data.imagem}` : LogoColor}
                                src={sideBarData.logoUrl}
                            />


                            {/* Icone para editar Perfil */}
                            {(sideBarData.actualView !== null) &&
                                <div
                                    style={{
                                        float: 'left',
                                        position: 'absolute',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        marginLeft: 100,
                                        marginTop: 110,
                                        minWidth: 33,
                                        minHeight: 33,
                                        backgroundColor: 'white',
                                        borderRadius: '50%',
                                        cursor: 'pointer'
                                    }}
                                    onClick={() => {
                                        if (sideBarData.actualView === 'home') {
                                            setContentModal(
                                                <FormDataUser
                                                    edit={profile_data}
                                                    avatar={profile_data.nivel.match(/Cliente/) ? `${backend_files_address}/${data_home[0].logomarca.filename}` : false}
                                                    callback={(_newData) => {
                                                        // set_show_modal(false)
                                                        setContentModal(null)
                                                    }} />
                                            )

                                        } else if (sideBarData.actualView === 'customer_detail') {
                                            setContentModal(
                                                <FormDataClient
                                                    updateData={()=> {
                                                        loadCustomers()}
                                                    }
                                                    edit={ navigation.page_content_data }
                                                    callbackfn={() => {
                                                        setContentModal(null)
                                                    }} />
                                            )
                                        }
                                        // set_show_modal(true)}
                                    }}
                                >
                                    <span className="material-icons" style={{ marginLeft: 1, color: primary_color }}>
                                        edit
                                    </span>
                                </div>
                            }
                        </div>


                        <div style={{ width: "100%", marginTop: 20 }} className="flex flexCol center-h">
                            <div style={{ width: "100%", justifyContent: 'center' }} className="flex">
                                <div>
                                    {/* NOME DO USUÁRIO LOGADO */}
                                    {/* <span style={{ fontSize: '1.1em' }}>{(profile_data && profile_data.nome != '') ? profile_data.nome : ''}</span> */}
                                    <span style={{ fontSize: '1.1em' }}>{sideBarData.userName}</span>
                                </div>
                            </div>
                        </div>


                        {/* Início Menu */}
                        <div style={{ marginTop: 30 }}>
                            {(sideBarData.actualView !== null) && (sideBarData.actualView === 'customer_detail') &&
                                <ul style={{ listStyleType: 'none' }}>
                                    <LineMenuHome style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                        <span className="material-icons">person</span>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>NOME DO CONSULTOR</span><br />
                                            <span><b>{navigation.page_content_data?.consultor}</b></span>
                                        </div>
                                    </LineMenuHome>
                                    <LineMenuHome style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                                        <span className="material-icons">subtitles</span>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>TELEFONE DO CONSULTOR</span><br />
                                            <span><b>{navigation.page_content_data?.contato_consultor}</b></span>
                                        </div>
                                    </LineMenuHome>
                                    <LineMenuHome style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', cursor: 'pointer' }}
                                        onClick={() => {
                                            setContentModal(
                                                <ListPrefixes
                                                    callbackfn={() => {
                                                        setContentModal(null)
                                                    }}
                                                    dados={navigation.page_content_data?.prefixos}
                                                />
                                            )
                                        }}
                                    >
                                        <span className="material-icons">menu</span>
                                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                                            <span>PREFIXOS</span>
                                            {/* <span><b>{navigation.page_content_data?.contato_consultor}</b></span> */}
                                        </div>
                                    </LineMenuHome>
                                </ul>
                            }


                            {(((sideBarData.actualView !== null) && (sideBarData.actualView === 'home')) || (sideBarData.actualView === 'listtickets') || (sideBarData.actualView === 'formtickets') || (sideBarData.actualView === 'ticket_detail')) &&
                                <ul style={{ listStyleType: 'none' }}>
                                    {/* {glpi_level === 'Cliente' &&
                                        <LineMenuHome>
                                            <Link
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                                onClick={() => {
                                                    set_navigation({ page_content: 'clientdetails' })
                                                    set_client_details(data_home[0])
                                                }}
                                                to={{
                                                    pathname: "/clientdetails",
                                                    state: { data_client: data_home[0] }
                                                }}>
                                                <span className="material-icons">subtitles</span>
                                                <span>ACESSOS</span>
                                            </Link>
                                        </LineMenuHome>
                                    } */}

                                    {(glpi_level.match(/Super|Técnico/i)) &&
                                        <LineMenuHome
                                            onClick={() => set_navigation({ page_content: 'formtickets' })}
                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                        >
                                            <span className="material-icons">playlist_add</span>
                                            <span>NOVO CHAMADO</span>
                                        </LineMenuHome>
                                    }

                                    {/* {(glpi_level.match(/Cliente/i)) &&
                                        <LineMenuHome
                                            onClick={() => set_navigation({ page_content: 'formtickets' })}
                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                        >
                                            <span className="material-icons">playlist_add</span>
                                            <span>NOVO CHAMADO</span>
                                        </LineMenuHome>
                                    } */}


                                    <LineMenuHome
                                        style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                        onClick={() => {
                                            window.location.href = '/chamados'
                                        }}
                                    >
                                        <span className="material-icons">list</span>
                                        <span>CHAMADOS</span>
                                    </LineMenuHome>

                                    {(glpi_level.match(/Super|Técnico-Admin/i)) &&
                                        <>
                                            <LineMenuHome
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                            >
                                                <Link to={{
                                                    pathname: "/caduser",
                                                    state: { data_client: data_home[0] }
                                                }}
                                                    style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                                    <span className="material-icons">people</span>
                                                    <span>USUÁRIOS</span>
                                                </Link>
                                            </LineMenuHome>
                                        </>
                                    }

                                    {/* {(glpi_level.match(/Super|Técnico/i)) &&
                                        <>
                                            <LineMenuHome
                                                onClick={() => {
                                                    set_navigation({ page_content: 'suport_calendar' })
                                                    if (window.location.pathname !== '/') {
                                                        window.location.href = '/'
                                                    }
                                                }}
                                                style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                            >
                                                <span className="material-icons">today</span>
                                                <span>CALENDÁRIO</span>
                                            </LineMenuHome>
                                        </>
                                    } */}

                                    {/* {(glpi_level.match(/Super|Técnico-Admin/i)) &&
                                        <LineMenuHome
                                            onClick={() => {
                                                set_navigation({ page_content: 'dash1' })
                                                if (window.location.pathname !== '/') {
                                                    window.location.href = '/'
                                                }
                                            }}
                                            style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
                                        >
                                            <span className="material-icons">insert_chart</span>
                                            <span>GRÁFICOS</span>
                                        </LineMenuHome>
                                    } */}
                                </ul>
                            }

                            <div
                                onClick={() => {
                                    if (deferredPrompt !== null) {
                                        deferredPrompt.prompt();
                                        //setupButton.disabled = true;
                                        // Wait for the user to respond to the prompt
                                        deferredPrompt.userChoice.then((choiceResult) => {
                                            if (choiceResult.outcome === 'accepted') {
                                                // console.log('PWA setup accepted');
                                                // hide our user interface that shows our A2HS button
                                                // setupButton.style.display = 'none';
                                                set_show_install_button(false)
                                            } else {
                                                console.log('PWA setup rejected');
                                            }
                                            set_deferredPrompt(null);
                                        });
                                    }
                                }}
                                style={{ width: 200, position: 'absolute', bottom: 15, display: show_install_button ? 'flex' : 'none', justifyContent: 'center', alignItems: 'center' }}
                            >
                                <div style={{ cursor: 'pointer', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                    <span className="material-icons">get_app</span>
                                    <span>Instale o APP</span>
                                </div>
                            </div>

                        </div>
                    </div>
                    {/* } */}
                </>
            </div >
        </div>
    );
}

export default LeftSideBar;


