import React, { useEffect, useState } from 'react';

import Button from '@material-ui/core/Button';

import Styles, { primary_color } from '../Styles';

import { api_bd } from '../../services/Api';
// import { Container } from './styles';

import Modal from '../Modal'


import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'

import navigationState from '../../atoms/navigate_state.atom';
import triagemTicket from '../../atoms/triagem_ticket.atom';

function TriagemTicket(props) {
    const styles = Styles();

    // const navigation_state = useRecoilValue(navigationState)

    const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

    const [showModal, setShowModal] = useState(false)
    const [etapas, seEtapas] = useState(navigation_state.data.flowNodes.steps || [])

    const [stateTriagem, setStateTriagem] = useRecoilState(triagemTicket)


    useEffect(() => {
        if (navigation_state.data?.flowNodes && navigation_state.data?.flowNodes.screening.made_contact !== '') {
            setStateTriagem(navigation_state.data.flowNodes.screening)
        }
    }, [])

    const Content1 = () => {

        const [stateTriagem, setStateTriagem] = useRecoilState(triagemTicket)

        return (
            stateTriagem && Object.keys(stateTriagem).length > 0 &&
            <div style={{ width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>
                <div>
                    <div style={{ fontSize: '1.3em', fontWeight: 'bold' }}>
                        Você entrou em contato com o cliente?
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 80, height: 40, backgroundColor: (stateTriagem.made_contact !== '' && stateTriagem.made_contact !== 'não') ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            props.setContentModal(<Content2 />)
                        }}>
                        Sim
                    </Button>

                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 80, height: 40, backgroundColor: stateTriagem.made_contact === 'não' ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            setStateTriagem(old => {
                                return { ...old, made_contact: 'não' }
                            })
                        }}>
                        Não
                    </Button>

                    <div style={{ marginLeft: 20 }}>
                        {stateTriagem?.made_contact &&
                            stateTriagem?.made_contact != '' &&
                            <div>
                                {stateTriagem?.made_contact !== 'não'
                                    ?
                                    <div>
                                        Resposta. SIM, {stateTriagem?.made_contact?.toUpperCase()}
                                    </div>
                                    :
                                    <div>
                                        Resposta. {stateTriagem?.made_contact?.toUpperCase()}
                                    </div>
                                }
                            </div>
                        }</div>
                </div >
                <hr />

                {/* ============================================================================================================= */}

                <div>
                    <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginTop: 20 }}>
                        A prioridade do ticket procede?
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 80, height: 40, backgroundColor: (stateTriagem.verified_priority?.newvalue && stateTriagem.verified_priority?.newvalue !== '' && stateTriagem.verified_priority?.newvalue === 'sim') ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            setStateTriagem(old => {
                                return {
                                    ...old,
                                    verified_priority: {
                                        newvalue: 'sim',
                                        oldvalue: navigation_state?.data.prioridade
                                    }
                                }
                            })
                        }}>
                        Sim
                    </Button>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 80, height: 40, backgroundColor: (stateTriagem.verified_priority?.newvalue && stateTriagem.verified_priority?.newvalue !== '' && stateTriagem.verified_priority?.newvalue !== 'sim') ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            props.setContentModal(<Content3 />)
                        }}>
                        Não
                    </Button >
                    <div style={{ marginLeft: 20 }}>
                        {stateTriagem.verified_priority?.newvalue &&
                            stateTriagem.verified_priority?.newvalue != '' &&
                            <div>
                                {stateTriagem.verified_priority?.newvalue === 'sim'
                                    ?
                                    <div>
                                        Resposta.  {stateTriagem.verified_priority?.newvalue?.toUpperCase()}
                                    </div>
                                    :
                                    <div>
                                        Resposta: NÃO <br />
                                        Alterado de:  {stateTriagem.verified_priority?.oldvalue?.toUpperCase()}<br />
                                        Para:  {stateTriagem.verified_priority?.newvalue?.toUpperCase()}<br />
                                    </div>
                                }
                            </div>
                        }</div>
                </div >
                <hr />

                {/* ============================================================================================================= */}

                <div>
                    <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginTop: 20 }}>
                        O Titulo do ticket está coeso?
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 80, height: 40, backgroundColor: (stateTriagem.verified_title.newvalue && stateTriagem.verified_title.newvalue !== '' && stateTriagem.verified_title.newvalue === 'sim') ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            setStateTriagem(old => {
                                return {
                                    ...old,
                                    verified_title: {
                                        newvalue: 'sim',
                                        oldvalue: navigation_state.data.titulo
                                    }
                                }
                            })
                        }}>
                        Sim
                    </Button>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 80, height: 40, backgroundColor: (stateTriagem.verified_title.newvalue && stateTriagem.verified_title.newvalue !== '' && stateTriagem.verified_title.newvalue !== 'não') ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            props.setContentModal(<Content4 />)
                        }}>
                        Não
                    </Button>
                    <div style={{ marginLeft: 20 }}>
                        {stateTriagem.verified_title?.newvalue &&
                            stateTriagem.verified_title?.newvalue != '' &&
                            <div>
                                {stateTriagem.verified_title?.newvalue === 'sim'
                                    ?
                                    <div>
                                        Resposta.  {stateTriagem.verified_title?.newvalue?.toUpperCase()}
                                    </div>
                                    :
                                    <div>
                                        Resposta: NÃO <br />
                                        Alterado de:  {stateTriagem.verified_title?.oldvalue?.toUpperCase()}<br />
                                        Para:  {stateTriagem.verified_title?.newvalue?.toUpperCase()}<br />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div >
                <hr />

                {/* ============================================================================================================= */}

                <div>
                    <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginTop: 20 }}>
                        A descrição está completa ou precisa ser adicionado algo mais?
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 80, height: 40, backgroundColor: (stateTriagem.verified_description?.newvalue && stateTriagem.verified_description?.newvalue !== '' && stateTriagem.verified_description?.newvalue === 'sim') ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            setStateTriagem(old => {
                                return {
                                    ...old,
                                    verified_description: {
                                        newvalue: 'sim',
                                        oldvalue: navigation_state.data.detalhes_solicitacao
                                    }
                                }
                            })
                        }}>
                        Sim
                    </Button>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 80, height: 40, backgroundColor: (stateTriagem.verified_description?.newvalue && stateTriagem.verified_description.newvalue !== '' && stateTriagem.verified_description.newvalue !== 'não') ? primary_color : 'gray', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            props.setContentModal(<Content5 />)
                        }}>
                        Não
                    </Button>
                    <div style={{ marginLeft: 20 }}>
                        {stateTriagem.verified_description?.newvalue &&
                            stateTriagem.verified_description?.newvalue != '' &&
                            <div>
                                {stateTriagem.verified_description?.newvalue === 'sim'
                                    ?
                                    <div>
                                        Resposta.  {stateTriagem.verified_description?.newvalue?.toUpperCase()}
                                    </div>
                                    :
                                    <div>
                                        Resposta: NÃO <br />
                                        Alterado de:  {stateTriagem.verified_description?.oldvalue?.toUpperCase()}<br />
                                        Para:  {stateTriagem.verified_description?.newvalue?.toUpperCase()}<br />
                                    </div>
                                }
                            </div>
                        }
                    </div>
                </div >
                <hr />

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 30 }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 200, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            // console.log(stateTriagem)

                            if ((stateTriagem.made_contact === '') || (stateTriagem.verified_description.newvalue === '') || (stateTriagem.verified_priority.newvalue === '') || (stateTriagem.verified_title.newvalue === '')) {
                                return window.alert('Todas as pergutnas são obrigatórias')
                            }

                            let response = await api_bd.put(`/tickets/${props.idTicket}`, {
                                flowNodes: {
                                    ...navigation_state.data.flowNodes,
                                    actualState: 2,
                                    screening: { ...stateTriagem, date: Date.now() }
                                }
                            })

                            set_navigation_state({
                                page_content: 'ticket_detail',
                                data: { ...response.data[0] }
                            })

                            props.callbackfn()
                        }}>
                        Finalizar triagem
                    </Button>
                </div >
            </div >
        )
    }

    const Content2 = () => {
        const [stateTriagem, setStateTriagem] = useRecoilState(triagemTicket)
        useEffect(() => {
            if (stateTriagem.made_contact === 'não') {
                setStateTriagem(old => {
                    return { ...old, made_contact: '' }
                })
            }
        }, [])
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>
                <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 30 }}>Qual foi o meio ?</div>
                <input
                    style={{ fontSize: '1.1em', padding: 5, width: 400 }}
                    type="text"
                    value={stateTriagem.made_contact}
                    placeholder="Telefone, email, whatsapp, etc..."
                    onChange={(e) => {
                        setStateTriagem(old => {
                            return { ...old, made_contact: e.target.value }
                        })
                    }}
                />

                <Button
                    className={styles.btn_submit}
                    variant="contained"
                    size="small"
                    style={{ width: 80, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                    onClick={async () => {
                        props.setContentModal(<Content1 />)
                        // props.callbackfn()
                    }}
                >
                    Salvar
                </Button>
            </div>
        )
    }


    const Content3 = () => {
        const [stateTriagem, setStateTriagem] = useRecoilState(triagemTicket)
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>
                <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 30 }}>Defina a nova Prioridade</div>

                <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'space-around' }}>
                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 150, height: 40, backgroundColor: 'green', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            const oldpriority = navigation_state.data.prioridade

                            let response = await api_bd.put(`/tickets/${props.idTicket}`, { prioridade: 'baixa' })

                            set_navigation_state({
                                page_content: 'ticket_detail',
                                data: { ...response.data[0] }
                            })

                            setStateTriagem(old => {
                                return {
                                    ...old,
                                    verified_priority: {
                                        newvalue: 'baixa',
                                        oldvalue: oldpriority
                                    }
                                }
                            })

                            props.setContentModal(<Content1 />)
                            // props.callbackfn()
                        }}
                    >
                        <span style={{ margin: 2, fontSize: '1.3em', color: '#fff' }} className="material-icons">warning</span> BAIXA
                    </Button>


                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 150, height: 40, backgroundColor: 'orange', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            const oldpriority = navigation_state.data.prioridade

                            let response = await api_bd.put(`/tickets/${props.idTicket}`, { prioridade: 'média' })
                            set_navigation_state({
                                page_content: 'ticket_detail',
                                data: { ...response.data[0] }
                            })

                            setStateTriagem(old => {
                                return {
                                    ...old,
                                    verified_priority: {
                                        newvalue: 'média',
                                        oldvalue: oldpriority
                                    }
                                }
                            })

                            props.setContentModal(<Content1 />)
                            // props.callbackfn()
                        }}
                    >
                        <span style={{ margin: 2, fontSize: '1.3em', color: '#fff' }} className="material-icons">warning</span> MÉDIA
                    </Button>

                    <Button
                        className={styles.btn_submit}
                        variant="contained"
                        size="small"
                        style={{ width: 150, height: 40, backgroundColor: 'red', color: '#fff', marginRight: 5 }}
                        onClick={async () => {
                            const oldpriority = navigation_state.data.prioridade

                            let response = await api_bd.put(`/tickets/${props.idTicket}`, { prioridade: 'alta' })
                            set_navigation_state({
                                page_content: 'ticket_detail',
                                data: { ...response.data[0] }
                            })

                            setStateTriagem(old => {
                                return {
                                    ...old,
                                    verified_priority: {
                                        newvalue: 'alta',
                                        oldvalue: oldpriority
                                    }
                                }
                            })

                            props.setContentModal(<Content1 />)
                            // props.callbackfn()
                        }}
                    >
                        <span style={{ margin: 2, fontSize: '1.3em', color: '#fff' }} className="material-icons">warning</span> ALTA
                    </Button>
                </div>
                <hr />
                <div>
                    <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginTop: 20, marginBottom: 10 }}>Como identificar a prioridade</div>
                    <div style={{ marginTop: 10, marginBottom: 5, color: 'green', fontWeight: 'bolder' }}>
                        # Prioridade Baixa: Implantação, ativação de fornecedores, ativação de cliente, suporte a cliente não recorrente, suporte a casos de reclamação não generalizada, adequação e padronização, atividades programadas ou que deveriam ser programadas, implantação de novo monitoramento, homologação.
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 5, color: 'orange', fontWeight: 'bolder' }}>
                        # Prioridade Média: Migração de rede ou cliente que envolve melhorias, ativação de cliente emergêncial, implantação emergêncial, analise em monitoramento ativo que está prejudicando a visibilidade da rede.
                    </div>
                    <div style={{ marginTop: 10, marginBottom: 5, color: 'red', fontWeight: 'bolder' }}>
                        # Prioridade Alta: Cliente indisponível, cliente com reclamação recorrente, cliente com tentativa de retenção em andamento, saturação causando reclamações recorrentes, problema em operadoras causando lentidão ou indisponíbilidade, demanda que envolve perda de receita.
                    </div>
                </div>
            </div>
        )
    }


    const Content4 = () => {
        const [stateTriagem, setStateTriagem] = useRecoilState(triagemTicket)
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>
                <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 30 }}>Defina o novo tíutlo do ticket</div>
                <input
                    style={{ fontSize: '1.1em', padding: 5, width: 400 }}
                    type="text"
                    value={stateTriagem.verified_title?.newvalue || ''}
                    placeholder="Título do ticket"
                    onChange={(e) => {
                        const oldtitle = navigation_state.data.titulo

                        setStateTriagem(old => {
                            return {
                                ...old,
                                verified_title: {
                                    newvalue: e.target.value,
                                    oldvalue: oldtitle
                                }
                            }
                        })
                    }}
                />

                <Button
                    className={styles.btn_submit}
                    variant="contained"
                    size="small"
                    style={{ width: 80, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                    onClick={async () => {
                        let response = await api_bd.put(`/tickets/${props.idTicket}`, { titulo: stateTriagem.verified_title.newvalue })
                        set_navigation_state({
                            page_content: 'ticket_detail',
                            data: { ...response.data[0] }
                        })

                        props.setContentModal(<Content1 />)
                        // props.callbackfn()
                    }}
                >
                    Salvar
                </Button>
            </div>
        )
    }


    const Content5 = () => {
        const [stateTriagem, setStateTriagem] = useRecoilState(triagemTicket)
        return (
            <div style={{ display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: '#fff', borderRadius: 5, padding: 50 }}>
                <div style={{ fontSize: '1.3em', fontWeight: 'bold', marginBottom: 30 }}>Defina a nova descrição do ticket</div>
                <input
                    style={{ fontSize: '1.1em', padding: 5, width: 400 }}
                    type="text"
                    value={stateTriagem.verified_description?.newvalue || ''}
                    placeholder="Descrição do ticket"
                    onChange={(e) => {
                        const olddescription = navigation_state.data.detalhes_solicitacao

                        setStateTriagem(old => {
                            return {
                                ...old,
                                verified_description: {
                                    newvalue: e.target.value,
                                    oldvalue: olddescription
                                }
                            }
                        })
                    }}
                />

                <Button
                    className={styles.btn_submit}
                    variant="contained"
                    size="small"
                    style={{ width: 80, height: 40, backgroundColor: primary_color, color: '#fff', marginRight: 5 }}
                    onClick={async () => {
                        let response = await api_bd.put(`/tickets/${props.idTicket}`, { detalhes_solicitacao: stateTriagem.verified_description.newvalue })
                        set_navigation_state({
                            page_content: 'ticket_detail',
                            data: { ...response.data[0] }
                        })

                        props.setContentModal(<Content1 />)
                        // props.callbackfn()
                    }}
                >
                    Salvar
                </Button>
            </div>
        )
    }





    return (
        <Content1 />
    )
}

export default TriagemTicket;