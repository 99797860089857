import React, { useState, useEffect } from 'react';

import Styles, { Fab, LineMenuHome } from '../components/Styles'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../atoms/user.atom'
import navigationState from '../atoms/navigate_state.atom'

import LeftSideBar from '../components/SideBars/LeftSideBar'
import CustomerList from '../components/page_contents/CustomerList'
import CustomerDetails from './CustomerDetails';

import FormDataTickets from '../components/Forms/FormDataTickets'
import ListTickets from '../components/Tables/Tickets'
import TicketDetail from '../components/page_contents/TicketDetail'
import DashBoard1 from '../components/page_contents/DashBoard1'
import TopTickets from '../components/page_contents/TopTickets'
import SuportCalendar from '../components/page_contents/SuportCalendar';

function Home(props) {
	const styles = Styles();

	const profile_user = useRecoilValue(profileData)

	const [navigation_state, set_navigation_state] = useRecoilState(navigationState)

	const [device_height, set_device_height] = useState(null)

	useEffect(() => {
		// const dw = document.documentElement.clientWidth
		const dh = document.documentElement.clientHeight
		// set_device_width(dw)
		set_device_height(dh)
		// set_navigation_state({page_content: 'home'})

	}, [])


	const ShowContent = () => {
		switch (navigation_state.page_content) {
			case 'formtickets':
				return (<FormDataTickets />)
				break;
			case 'ticket_detail':
				return (<TicketDetail />)
				break;
			case 'listtickets':
				return (<ListTickets />)
				break
			// case 'suport_calendar':
			// 	return (<SuportCalendar />)
			// 	break;
			// case 'dash1':
			// 	return (<DashBoard1 />)
			// 	break;
			// case 'toptickets':
			// 	return (<TopTickets />)
			// 	break;
			case 'home':
				return (<CustomerList />)
			case 'customer_detail':
				return (<CustomerDetails />)
			default:
				return (<CustomerList />)
				break;
		}
	}


	return (
		<div className="flex flexRow height_less_header" style={{
			width: "100%",
			alignItems: "flex-start"
		}}>
			<LeftSideBar />

			{navigation_state?.page_content && Object.keys(profile_user).length > 0 &&
				// (() => {
				// 	switch (navigation_state.page_content) {
				// 		case 'formtickets':
				// 			return (<FormDataTickets />)
				// 			break;
				// 		case 'ticket_detail':
				// 			return (<TicketDetail />)
				// 			break;
				// 		case 'listtickets':
				// 			return (<ListTickets />)
				// 			break
				// 		case 'suport_calendar':
				// 			return (<SuportCalendar />)
				// 			break;
				// 		case 'dash1':
				// 			return (<DashBoard1 />)
				// 			break;
				// 		case 'toptickets':
				// 			return (<TopTickets />)
				// 			break;
				// 		case 'home':
				// 			return (<CustomerList />)
				// 		default:
				// 			return (<CustomerList />)
				// 			break;
				// 	}
				// })()
				<ShowContent />
			}
		</div >

	);
}

export default Home;


