import React, {
    useState,
    useEffect
} from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';


import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles from '../Styles'

import { api_bd } from '../../services/Api'


import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Autocomplete from '@material-ui/lab/Autocomplete';

import municipios from '../../assets/municipios_brasil.json';

import { useSetRecoilState, useRecoilValue } from 'recoil'
import clientDetailsData from '../../atoms/client_details.atom'
import { profileData } from '../../atoms/user.atom'

function FormDataServices(props) {
    const { data_client, width, callbackfn, editData } = props
    const update_client_data = useSetRecoilState(clientDetailsData)
    const user_sgi = useRecoilValue(profileData)

    const styles = Styles();

    const [data_cad, set_data_cad] = useState({
        ativo: true,
        tipo: 'Dados IP BGP',

        asn_cliente: "",
        ativo: "",
        banda_contatada: "",
        designador: "",
        estacao_ponto_a: "",
        estacao_ponto_b: "",
        hostname_pe: "",
        ip_cpe: "",
        ip_cpe_pe_ponto_a: "",
        ip_cpe_pe_ponto_b: "",
        ip_equpamento: "",
        ip_p2p_ipv4: "",
        ip_p2p_ipv6: "",
        ip_pe: "",
        link_planilha: "",
        marca_equipamento: "",
        modelo_equipamento: "",
        nome_pop: "",
        observacao: "",
        ponto_a: "",
        ponto_b: "",
        regiao_cliente: "",
        tipo: "",
        vlan_gerencia_cpe: "",
        vlan_p2p: "",
        vlans: "",
        vlans_ptt_fla: "",
        vlans_ptt_rjo: "",
        vlans_ptt_spo: ""
    })

    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [type_data, set_type_data] = useState('Dados IP BGP')

    const lista_municipios = [...municipios]

    const [list_stations, set_list_stations] = useState([])

    const carregar_estacoes = async () => {
        const lista = await api_bd.get('/stations')
        const list = lista.data.filter(sta => sta.user === data_client._id).sort((a, b) => a.codigo_estacao.localeCompare(b.codigo_estacao))
        set_list_stations(list)
    }


    const handle_data_cad = (key, value) => {
        // console.log(key, value)

        if (key === 'tipo') {
            set_type_data(value)
        }


        let data = data_cad 
        data[key] = value
        
        set_data_cad(data)
    }


    useEffect(() => {
        (async () => {
            await carregar_estacoes()
        })()

        if (editData) {
            // console.log('editData:', editData)
            set_type_data(editData.tipo)
            set_data_cad(editData)
            // set_data_cad(old => {
            //     return ({ ...old, ...editData })
            // })
        }
    }, [])


    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                <CardContent>
                    <CardHeader
                        title="Adicionar Serviço"
                        subheader=""
                    />
                    <form className={styles.form_cad_user} noValidate autoComplete="off">

                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Tipo &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={data_cad.tipo}
                                    value={data_cad.tipo}
                                    labelId="select-label"
                                    id="select-nivel"
                                    onChange={e => {
                                        handle_data_cad("tipo", e.target.value)
                                    }}
                                >
                                    <MenuItem value="Dados IP BGP">Dados IP BGP</MenuItem>
                                    <MenuItem value="Dados IP Dedicado">Dados IP Dedicado</MenuItem>
                                    <MenuItem value="Dados IP CDN">Dados IP CDN</MenuItem>
                                    <MenuItem value="LAN Connect">LAN Connect</MenuItem>
                                    <MenuItem value="LAN Connect IX FLEX">LAN Connect (IX-FLEX)</MenuItem>
                                    <MenuItem value="VPN L3">VPN L3</MenuItem>
                                    <MenuItem value="Co Location">Co-Location</MenuItem>
                                    <MenuItem value="Aluguel de equipamentos">Aluguel de equipamentos</MenuItem>
                                </Select>
                            </FormControl>
                        </div>




                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Status &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={data_cad.ativo}
                                    value={data_cad.ativo === true ? 'Ativo' : 'Inativo'}
                                    labelId="select-label"
                                    id="select-nivel"
                                    onChange={e => {
                                        // handle_data_cad("ativo", e.target.value)
                                        set_data_cad(old => {
                                            const val = (e.target.value === 'Ativo') ? true : false 
                                            return({...old, ativo: val})
                                        })
                                    }}
                                >
                                    <MenuItem value="Ativo">Ativo</MenuItem>
                                    <MenuItem value="Inativo">Inativo</MenuItem>
                                </Select>
                            </FormControl>
                        </div>



                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    // handle_data_cad("designador", e.target.value)
                                    set_data_cad(old => {
                                        return({...old, designador: e.target.value})
                                    })
                                }}
                                id="designador"
                                label="Designador"
                                required={true}
                                value={data_cad.designador}
                            />
                        </div>


                        {data_cad.tipo.match(/Dados IP BGP|Dados IP CDN/) &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        // handle_data_cad("asn_cliente", e.target.value)
                                        set_data_cad(old => {
                                            return({...old, asn_cliente: e.target.value})
                                        })
                                    }}
                                    id="asn_cliente"
                                    label="ASN do Cliente"
                                    required={true}
                                    value={data_cad.asn_cliente}
                                />
                            </div>
                        }

                        {data_cad.tipo.match(/Dados IP BGP|Dados IP Dedicado|Dados IP CDN/) &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("ip_p2p_ipv4", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, ip_p2p_ipv4: e.target.value})
                                            })
                                        }}
                                        id="ip_p2p_ipv4"
                                        label="IP de P2P IPV4"
                                        required={true}
                                        value={data_cad.ip_p2p_ipv4}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("ip_p2p_ipv6", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, ip_p2p_ipv6: e.target.value})
                                            })
                                        }}
                                        id="ip_p2p_ipv6"
                                        label="IP de P2P IPV6"
                                        required={true}
                                        value={data_cad.ip_p2p_ipv6}
                                    />
                                </div>
                            </>
                        }

                        {data_cad.tipo.match(/Dados IP BGP|Dados IP Dedicado|Dados IP CDN/) &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("vlan_p2p", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, vlan_p2p: e.target.value})
                                            })
                                        }}
                                        id="vlan_p2p"
                                        label="VLAN de P2P"
                                        required={true}
                                        value={data_cad.vlan_p2p}
                                    />
                                </div>


                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("ip_pe", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, ip_pe: e.target.value})
                                            })
                                        }}
                                        id="ip_pe"
                                        label="IP do PE"
                                        required={true}
                                        value={data_cad.ip_pe}
                                    />
                                </div>


                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("hostname_pe", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, hostname_pe: e.target.value})
                                            })
                                        }}
                                        id="hostname_pe"
                                        label="HOSTNAME do PE"
                                        required={true}
                                        value={data_cad.hostname_pe}
                                    />
                                </div>
                            </>
                        }

                        {data_cad.tipo.match(/LAN Connect IX FLEX|LAN Connect/) &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        // handle_data_cad("ponto_a", e.target.value)
                                        set_data_cad(old => {
                                            return({...old, ponto_a: e.target.value})
                                        })
                                    }}
                                    id="ponto_a"
                                    label="Ponto A"
                                    required={true}
                                    value={data_cad.ponto_a}
                                />
                            </div>
                        }

                        {data_cad.tipo.match(/LAN Connect/) &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("ponto_b", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, ponto_b: e.target.value})
                                            })
                                        }}
                                        id="ponto_b"
                                        label="Ponto B"
                                        required={true}
                                        value={data_cad.ponto_b}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("ip_cpe_pe_ponto_a", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, ip_cpe_pe_ponto_a: e.target.value})
                                            })
                                        }}
                                        id="ip_cpe_pe_ponto_a"
                                        label="IP do CPE/PE do Ponto A"
                                        required={true}
                                        value={data_cad.ip_cpe_pe_ponto_a}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("ip_cpe_pe_ponto_b", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, ip_cpe_pe_ponto_b: e.target.value})
                                            })
                                        }}
                                        id="ip_cpe_pe_ponto_b"
                                        label="IP do CPE/PE do Ponto B"
                                        required={true}
                                        value={data_cad.ip_cpe_pe_ponto_b}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("estacao_ponto_a", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, estacao_ponto_a: e.target.value})
                                            })
                                        }}
                                        id="estacao_ponto_a"
                                        label="Estação ponto A"
                                        required={true}
                                        value={data_cad.estacao_ponto_a}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("estacao_ponto_b", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, estacao_ponto_b: e.target.value})
                                            })
                                        }}
                                        id="estacao_ponto_b"
                                        label="Estação ponto B"
                                        required={true}
                                        value={data_cad.estacao_ponto_b}
                                    />
                                </div>


                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("vlans", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, vlans: e.target.value})
                                            })
                                        }}
                                        id="vlans"
                                        label="VLANs"
                                        required={true}
                                        value={data_cad.vlans}
                                    />
                                </div>
                            </>
                        }

                        {data_cad.tipo.match(/LAN Connect IX FLEX/) &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("vlans_ptt_fla", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, vlans_ptt_fla: e.target.value})
                                            })
                                        }}
                                        id="vlans_ptt_fla"
                                        label="VLANs PTT FLA"
                                        required={true}
                                        value={data_cad.vlans_ptt_fla}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("vlans_ptt_spo", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, vlans_ptt_spo: e.target.value})
                                            })
                                        }}
                                        id="vlans_ptt_spo"
                                        label="VLANs PTT SPO"
                                        required={true}
                                        value={data_cad.vlans_ptt_spo}
                                    />
                                </div>


                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("vlans_ptt_rjo", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, vlans_ptt_rjo: e.target.value})
                                            })
                                        }}
                                        id="vlans_ptt_rjo"
                                        label="VLANs PTT RJO"
                                        required={true}
                                        value={data_cad.vlans_ptt_rjo}
                                    />
                                </div>
                            </>
                        }

                        {data_cad.tipo.match(/Dados IP BGP|Dados IP Dedicado|Dados IP CDN|LAN Connect IX FLEX/) &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("ip_cpe", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, ip_cpe: e.target.value})
                                            })
                                        }}
                                        id="ip_cpe"
                                        label="IP do CPE"
                                        required={true}
                                        value={data_cad.ip_cpe}
                                    />
                                </div>


                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("vlan_gerencia_cpe", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, vlan_gerencia_cpe: e.target.value})
                                            })
                                        }}
                                        id="vlan_gerencia_cpe"
                                        label="VLAN de gerência do CPE"
                                        required={true}
                                        value={data_cad.vlan_gerencia_cpe}
                                    />
                                </div>
                            </>
                        }

                        {data_cad.tipo.match(/Dados IP BGP|Dados IP Dedicado|Dados IP CDN|LAN Connect|LAN Connect IX FLEX/) &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        // handle_data_cad("banda_contatada", e.target.value)
                                        set_data_cad(old => {
                                            return({...old, banda_contatada: e.target.value})
                                        })
                                    }}
                                    id="banda_contatada"
                                    label="Banda Contratada"
                                    required={true}
                                    value={data_cad.banda_contatada}
                                />
                            </div>
                        }

                        {data_cad.tipo.match(/VPN L3/) &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        // handle_data_cad("link_planilha", e.target.value)
                                        set_data_cad(old => {
                                            return({...old, link_planilha: e.target.value})
                                        })
                                    }}
                                    id="link_planilha"
                                    label="Link da planilha"
                                    required={true}
                                    value={data_cad.link_planilha}
                                />
                            </div>
                        }


                        {data_cad.tipo.match(/Dados IP BGP|Dados IP Dedicado|Dados IP CDN|Co Location/) &&
                            <div style={{ width: "100%" }}>
                                <TextField
                                    className={styles.form_input}
                                    onChange={e => {
                                        // handle_data_cad("nome_pop", e.target.value)
                                        set_data_cad(old => {
                                            return({...old, nome_pop: e.target.value})
                                        })
                                    }}
                                    id="nome_pop"
                                    label="Nome do POP"
                                    required={true}
                                    value={data_cad.nome_pop}
                                />
                            </div>
                        }

                        {data_cad.tipo.match(/Aluguel de equipamentos/) &&
                            <>
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("regiao_cliente", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, regiao_cliente: e.target.value})
                                            })
                                        }}
                                        id="regiao_cliente"
                                        label="Região do Cliente"
                                        required={true}
                                        value={data_cad.regiao_cliente}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("ip_equpamento", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, ip_equpamento: e.target.value})
                                            })
                                        }}
                                        id="ip_equpamento"
                                        label="IP do Equipamento"
                                        required={true}
                                        value={data_cad.ip_equpamento}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("modelo_equipamento", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, modelo_equipamento: e.target.value})
                                            })
                                        }}
                                        id="modelo_equipamento"
                                        label="Modelo do Equipamento"
                                        required={true}
                                        value={data_cad.modelo_equipamento}
                                    />
                                </div>

                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            // handle_data_cad("marca_equipamento", e.target.value)
                                            set_data_cad(old => {
                                                return({...old, marca_equipamento: e.target.value})
                                            })
                                        }}
                                        id="marca_equipamento"
                                        label="Marca do Equipamento"
                                        required={true}
                                        value={data_cad.marca_equipamento}
                                    />
                                </div>
                            </>
                        }

                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    // handle_data_cad("observacao", e.target.value)
                                    set_data_cad(old => {
                                        return({...old, observacao: e.target.value})
                                    })
                                }}
                                id="observacao"
                                label="Observação"
                                required={true}
                                value={data_cad.observacao}
                            />
                        </div>



                        <div style={{ width: "100%" }}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {

                                    let { tipo, ativo, asn_cliente, ip_p2p_ipv4, ip_p2p_ipv6, vlan_p2p, ip_pe, hostname_pe, ponto_a, ponto_b, ip_cpe_pe_ponto_a, ip_cpe_pe_ponto_b, estacao_ponto_a, estacao_ponto_b, vlans, vlans_ptt_fla, vlans_ptt_spo, vlans_ptt_rjo, ip_cpe, vlan_gerencia_cpe, banda_contatada, link_planilha, nome_pop, designador, regiao_cliente, ip_equpamento, modelo_equipamento, marca_equipamento, observacao } = data_cad

                                    let dataToSend;

                                    switch (data_cad.tipo) {
                                        case 'Dados IP BGP':
                                            dataToSend = { tipo, ativo, designador, asn_cliente, ip_p2p_ipv4, ip_p2p_ipv6, vlan_p2p, vlan_gerencia_cpe, ip_cpe, ip_pe, hostname_pe, nome_pop, banda_contatada, observacao }
                                            break;
                                        case 'Dados IP Dedicado':
                                            dataToSend = { tipo, ativo, designador, ip_p2p_ipv4, ip_p2p_ipv6, vlan_p2p, vlan_gerencia_cpe, ip_cpe, ip_pe, hostname_pe, nome_pop, banda_contatada, observacao }
                                            break;
                                        case 'Dados IP CDN':
                                            dataToSend = { tipo, ativo, designador, asn_cliente, ip_p2p_ipv4, ip_p2p_ipv6, vlan_p2p, vlan_gerencia_cpe, ip_cpe, ip_pe, hostname_pe, nome_pop, banda_contatada, observacao }
                                            break;
                                        case 'LAN Connect':
                                            dataToSend = { tipo, ativo, designador, ponto_a, ponto_b, ip_cpe_pe_ponto_a, ip_cpe_pe_ponto_b, estacao_ponto_a, estacao_ponto_b, vlans, banda_contatada, observacao }
                                            break;
                                        case 'LAN Connect IX FLEX':
                                            dataToSend = { tipo, ativo, designador, ponto_a, vlans_ptt_fla, vlans_ptt_spo, vlans_ptt_rjo, ip_cpe, vlan_gerencia_cpe, banda_contatada, observacao }
                                            break;
                                        case 'VPN L3':
                                            dataToSend = { tipo, ativo, designador, link_planilha, observacao }
                                            break;
                                        case 'Co Location':
                                            dataToSend = { tipo, ativo, designador, nome_pop, observacao }
                                            break;
                                        case 'Aluguel de equipamentos':
                                            dataToSend = { tipo, ativo, designador, regiao_cliente, ip_equpamento, modelo_equipamento, marca_equipamento, observacao }
                                            break;

                                        default:
                                            dataToSend = {}
                                            break;
                                    }

                                    if (dataToSend.tipo) {
                                        try {
                                            if (editData) {
                                                const resp = await api_bd.put(`/customers/editserviceall/${data_client._id}`, {
                                                    serviceData: { serviceId: props.editData._id, data: dataToSend },
                                                    userData: user_sgi
                                                })
                                                update_client_data(resp.data)
                                                callbackfn(resp.data)
                                                alert(`Dados atualizados com sucesso!`)
                                            } else {
                                                const resp = await api_bd.put(`/customers/addservice/${data_client._id}`, {
                                                    serviceData: dataToSend,
                                                    userData: user_sgi
                                                })
                                                update_client_data(resp.data)
                                                callbackfn(resp.data)
                                                alert(`Dados inseridos com sucesso!`)
                                            }
                                        } catch (error) {
                                            if (error.message.indexOf('code 400') > -1) {
                                                alert("Verifique os dados e tente novamente.")
                                            }
                                        }
                                    }

                                }}
                            >
                                Salvar
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default FormDataServices;

//Schema Acesso
//{"localidade":"Capim","situacao":"Ativo","tipo":"Ativo de Rede","equipamento":"roteador","fabricante":"intelbras","modelo":"NMS3000","descricao":"descTESTE","ip_dominio_link":"user.com","porta":"123","usuario_tecnico":"userintec","senha_tecnico":"senhaintec","senha_root":"senharoot","usuario_cliente":"userclient","senha_cliente":"senhaclient","observacoes":"observaaaaa"}



