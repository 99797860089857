import React from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { primary_color } from './Styles';

function ListPrefixes(props) {
    return (
        <Card  style={{padding: 30, width: '80%'}}>
            <CardContent>
                {props.dados.map(el => (
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', marginBottom: 30, borderBottom: '1px solid #c3c3c3'}}>
                        <p><b>ASN:</b> {el.asn}</p>
                        <p><b>PREFIX:</b> {el.prefix}</p>
                        <p><b>CUSTOMER:</b> {el.customer}</p>
                    </div>
                ))}
    {props.dados.length === 0 &&
        <span style={{fontSize: '1.3em', color: primary_color, fontWeight: 'bold'}}>NENHUM PREFIXO CADASTRADO</span>
    }
            </CardContent>
        </Card>
    );
}

export default ListPrefixes;