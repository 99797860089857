import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
    key: '@sgitelynavigation', // this key is using to store data in local storage
    storage: localStorage, // configurate which stroage will be used to store the data
})

const navigationState = atom({
    key: 'navigationState', // unique ID (with respect to other atoms/selectors)
    default: {}, // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

export default navigationState