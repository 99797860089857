import React, {
    useState,
    useEffect
} from 'react';

import {
    Link,
    useHistory
} from "react-router-dom";

import Card from '@material-ui/core/Card';

import CardContent from '@material-ui/core/CardContent';

import Styles, { colors_access_types, primary_color } from '../components/Styles'

import { useRecoilState, useRecoilValue } from 'recoil'

import clientDetailsData from '../atoms/client_details.atom'

import { profileData } from '../atoms/user.atom'

import Modal from './Modal';

import { TextField } from '@material-ui/core';

import FormDataServices from './Forms/FormDataServices';

import api_bd from '../services/Api';

const { services_labels, service_colors } = require('../config.json')




function ListServicesByType(props) {
    const styles = Styles();
    const { _filter, _situacao, _classes, _style, set_content_modal, set_access_to_edit, set_show_modal, dados, customer_id } = props

    const data_services = dados.map(obj => {
        return Object.keys(obj)
            .sort()
            .reduce((accumulator, key) => {
                accumulator[key] = obj[key];
                return accumulator;
            }, {});
    })

    const data_client = useRecoilValue(clientDetailsData)
    const user_sgi = useRecoilValue(profileData)

    const [showEditModal, setShowEditModal] = useState(false)
    const [contentEditModal, setContentEditModal] = useState(null)

    const UpdateDataCardValue = (propsCard) => {
        const [dados, setDados] = useState({})
        const [data_client, set_data_client] = useRecoilState(clientDetailsData)

        useEffect(() => {
            setDados({
                serviceData: propsCard.data,
                userData: propsCard.user
            })
        }, [])

        const handleData = (newValue) => {
            setDados(old => {
                return ({
                    serviceData: { ...old.serviceData, data: newValue },
                    userData: { ...old.userData }
                })
            })
        }

        return (
            Object.keys(dados).length > 0 &&
            <Card style={{ width: '80%', backgroundColor: '#red', marginTop: 20 }}>
                <CardContent>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <span style={{ marginLeft: 0, fontSize: '1.3em', color: primary_color }}><b style={{ fontSize: '1em', color: primary_color }}>Insira um novo valor para o campo:</b> {dados.serviceData.title.toUpperCase()}</span>
                        <TextField
                            style={{ marginTop: 10 }}
                            onChange={e => {
                                handleData(e.target.value)
                            }}
                            // label={dados.serviceData.title}
                            value={dados.serviceData.data}
                            multiline={dados.serviceData.multiline}

                        />
                        <div
                            className='hoverBackgroundCorlorEffect'
                            style={{ cusor: 'pointer', color: '#fff', fontWeight: 'bold', width: 100, height: 40, marginTop: 20, borderRadius: 6, display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                            onClick={async () => {
                                await api_bd.put(`/customers/editservice/${customer_id}`, dados).then(resp => {
                                    if (resp.status && resp.status === 200) {
                                        if (Object.keys(resp.data).length > 0) {
                                            set_data_client(resp.data)
                                            propsCard.callbackfn()
                                        }
                                    }
                                })
                            }}
                        >
                            ATUALIZAR
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }



    useEffect(() => {
        if (contentEditModal === null) {
            setShowEditModal(false)
        } else if (contentEditModal !== null) {
            setShowEditModal(true)
        }
    }, [contentEditModal])


    return (
        <>
            <div className={_classes ? `${_classes}` : 'flex flexRow flexWrap justify-content-start'} style={_style ? _style : {}}>

                {showEditModal &&
                    <Modal callbackfn={() => {
                        setContentEditModal(null)
                    }}>
                        {contentEditModal}
                    </Modal>
                }



                {
                    data_services && data_services.length > 0 && _filter.map(filtro => (
                        data_services.map((serv) => (serv.tipo === filtro && serv.ativo === _situacao &&
                            <Card
                                key={`${serv._id}${serv.tipo}`}
                                className={styles.card}
                                style={{ width: 280, maxWidth: 280, height: 330, maxHeight: 330 }}
                            >
                                <CardContent style={{ padding: 0 }}>
                                    <div
                                        className="flex justify-content-sb"

                                        onDoubleClick={() => {
                                            setContentEditModal(
                                                <FormDataServices
                                                    editData={serv}
                                                    data_client={data_client}
                                                    callbackfn={() => {
                                                        setContentEditModal(null)
                                                    }}
                                                />
                                            )
                                        }}

                                        style={{
                                            width: "100%",
                                            backgroundColor: serv.ativo === true ? `${service_colors[serv.tipo]}` : `${service_colors[serv.tipo]}33`,
                                            color: 'white',
                                            padding: 10,
                                            cursor: 'pointer'
                                            // cursor: user_sgi.nivel.match(/Super/i) || user_sgi.nivel.match(/Técnico/i) ? "pointer" : ""
                                        }}
                                    >
                                        <div className="flex">
                                            {serv.tipo}
                                        </div>
                                    </div>

                                    <div style={{
                                        width: "100%",
                                        padding: 10,
                                        color: serv.ativo === true ? '#3c5ca6' : '#3c5ca655'
                                    }}>
                                        {Object.keys(serv).map((keyindex) => (
                                            (serv[keyindex] !== '' && keyindex !== 'ativo' && keyindex !== '_id')
                                                ?
                                                <div style={{ fontSize: 12, marginBottom: 5, wordWrap: 'break-word', cursor: 'pointer' }}
                                                    onDoubleClick={() => {
                                                        if (keyindex !== 'tipo') {
                                                            setContentEditModal(
                                                                <UpdateDataCardValue
                                                                    user={user_sgi}
                                                                    data={{
                                                                        keyindex,
                                                                        title: services_labels[keyindex],
                                                                        data: serv[keyindex],
                                                                        serviceId: serv._id,
                                                                        multiline: false
                                                                    }}
                                                                    callbackfn={() => {
                                                                        setContentEditModal(null)
                                                                    }}
                                                                />
                                                            )
                                                        }
                                                    }}
                                                >
                                                    <b>{services_labels[keyindex]}: </b> {serv[keyindex]}
                                                </div>
                                                : null
                                        ))}
                                    </div>
                                </CardContent>
                            </Card>
                        ))
                    ))
                }
            </div>
        </>
    )
}


export default ListServicesByType;