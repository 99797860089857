import { atom, selector } from 'recoil'
// import { recoilPersist } from 'recoil-persist'

// const { persistAtom } = recoilPersist({
//     key: '@sgitelycustomers', // this key is using to store data in local storage
//     storage: localStorage, // configurate which stroage will be used to store the data
// })

const userTickets = atom({
    key: 'userTickets', // unique ID (with respect to other atoms/selectors)
    default: [], // default value (aka initial value)
    // effects_UNSTABLE: [persistAtom],
});

// export const listAccess = selector({
//     key: 'listAccess', // unique ID (with respect to other atoms/selectors)
//     get: ({ get }) => {
//         const data = get(userTickets);
//         if(data.acessos && data.acessos.length > 0){
//             const acessos = [...data.acessos]
//             const ordered_data = acessos.sort((a, b) => a.descricao.localeCompare(b.descricao))
//             return (ordered_data)
//         }else{
//             return ([])
//         }
        
//     },
// });

export default userTickets