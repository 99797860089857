import React, { useState, useEffect } from 'react';

import * as IoIcons from 'react-icons/io';

const Modal = (props) => {
    const { callbackfn } = props

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const style_modal = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        backgroundColor: '#00000055',
        left: 0,
        top: 0,
        position: 'absolute',
        float: 'left',
        minWidth: '100%',
        maxWidth: '100%',
        height: `${device_height}px`,
        maxHeight: `${device_height}px`,
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: "auto",
        zIndex: 100
    }

    const handleClose = () => {
        props.callbackfn();
    };

    useEffect(()=>{
        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)

        document.onkeydown = function(evt) {
			evt = evt || window.event;
			var isEscape = false;
			if ("key" in evt) {
				isEscape = (evt.key === "Escape" || evt.key === "Esc");
			} else {
				isEscape = (evt.keyCode === 27);
			}
            
			if (isEscape) {
                handleClose()
			}
		}
    },[])

    return (
        <div style={style_modal} >
            <div style={{ width: '100%', position: 'relative' }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    width: '100%',
                    maxHeight: 60,
                    marginBottom: 10,
                    justifyContent: 'flex-end',
                    // right: 30,
                    ...props.btn_close_position
                }}>
                    <IoIcons.IoIosCloseCircleOutline
                        size={60}
                        onClick={handleClose}
                        style={{
                            // position: 'fixed',
                            marginRight: '10%',
                            top: 0,
                            color: 'white',
                            paddingTop: 15,
                            cursor: 'pointer'
                        }}
                    />
                </div>

                <div
                    style={{
                        display: 'flex',
                        width: props._width ? props._width : '100%',
                        justifyContent: 'center',...props._style
                    }} 
                >
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export default Modal;