import React, {
    useEffect,
    useState,
} from 'react';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import FileUploader from '../FileUploader'

import Styles from '../Styles'

import { api_bd } from '../../services/Api'

import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import * as IoIcons from 'react-icons/io';

import customerData from '../../atoms/customers.atom'
import navigationState from '../../atoms/navigate_state.atom';
import { profileData } from '../../atoms/user.atom'
import { useRecoilState, useSetRecoilState, useRecoilValue } from 'recoil';


function FormDataClient(props) {
    const { width, set_data_new_client } = props
    const styles = Styles()
    const user_sgi = useRecoilValue(profileData)

    const [data_client, set_data_client] = useState({
        logomarca: null,
        razao_social: '',
        cnpj: '',
        contato: '',
        consultor: '',
        contato_consultor: '',
        situacao: 'ativo',
        cliente_desde: '',
        observacao: '',
        nome_fantasia: '',
    })

    const [navigation, setNavigation] = useRecoilState(navigationState)

    const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [logomarca, set_logomarca] = useState(null);

    const set_data_home = useSetRecoilState(customerData)


    const loadCustomers = async () => {
		const dados = await api_bd.get(`/customers`)
		if (dados.data.length > 0) {
			const ordered_data = dados.data //? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
			set_data_home(ordered_data)
		}
	}


    const handle_data_client = (key, value) => {
        let data = { ...data_client }
        data[key] = value
        set_data_client(data)
    }


    useEffect(() => {
        if (props.edit) {
            set_data_client(old => {
                return ({ ...old, ...props.edit })
            })
        }
    }, [])

    return (
        <div className={styles.div_root_component} style={{ width }}>
            <Card className={styles.card}>
                {data_client.situacao !== 'ativo' &&
                    <IoIcons.IoIosTrash
                        size={26}
                        style={{ position: 'absolute', marginTop: 30, marginLeft: 155, cursor: "pointer" }}
                        onClick={async () => {
                            if (window.confirm(`Tem certeza que excluir os dados de: ${data_client.nome_fantasia}`)) {
                                try {
                                    const resp = await api_bd.delete(`/customers/${data_client._id}/${user_sgi.id}`)
                                    await loadCustomers()
                                    setNavigation({
                                        page_content: 'home',
                                        page_content_data: {}
                                    })
                                    props.callbackfn()
                                } catch (error) {
                                    // if (error.message.indexOf('code 400') > -1) {
                                    //     alert("Ocorreu um erro tente novamente.")
                                    // }
                                }
                            }
                        }}
                    />
                }
                <CardContent>
                    <CardHeader
                        title={props.edit ? 'Editar Cliente' : 'Adicionar Cliente'}
                        subheader=""
                    />
                    <form className={styles.form_cad_user} noValidate autoComplete="off">
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("razao_social", e.target.value)
                                }}
                                id="razao_social"
                                label="Razão Social"
                                required={true}
                                value={data_client.razao_social}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("nome_fantasia", e.target.value)
                                }}
                                id="nome_fantasia"
                                label="Nome Fantasia"
                                required={true}
                                value={data_client.nome_fantasia}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("cnpj", e.target.value)
                                }}
                                id="cnpj"
                                label="CNPJ"
                                value={data_client.cnpj}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("contato", e.target.value)
                                }}
                                id="contato"
                                label="Telefone do Cliente"
                                value={data_client.contato}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("consultor", e.target.value)
                                }}
                                id="consultor"
                                label="Nome do Consultor"
                                value={data_client.consultor}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("contato_consultor", e.target.value)
                                }}
                                id="contato_consultor"
                                label="Telefone do Consultor"
                                value={data_client.contato_consultor}

                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                <InputLabel htmlFor="select-label">Situação &nbsp;  *</InputLabel>
                                <Select
                                    defaultValue={data_client.situacao}
                                    value={data_client.situacao}
                                    labelId="select-label"
                                    id="situacao"
                                    onChange={e => {
                                        handle_data_client("situacao", e.target.value)
                                    }}

                                >
                                    <MenuItem value="ativo">Ativo</MenuItem>
                                    <MenuItem value="inativo">Inativo</MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                        <div style={{ width: "100%" }}>
                            <TextField
                                className={styles.form_input}
                                onChange={e => {
                                    handle_data_client("cliente_desde", e.target.value)
                                }}
                                id="cliente_desde"
                                label="Cliente Desde"
                                value={data_client.cliente_desde}
                            />
                        </div>
                        <div style={{ width: "100%" }}>
                            <div style={{
                                color: "#888888",
                                marginLeft: 0,
                                marginTop: 15,
                                marginBottom: 5,
                                top: 10
                            }}>Logotipo</div>
                            <FileUploader set_file={set_logomarca} />
                        </div>
                        <div style={{ width: "100%" }}>
                            <Button
                                className={styles.btn_submit}
                                variant="contained"
                                color="primary"
                                size="small"
                                disabled={submit_is_disabled}
                                onClick={async () => {

                                    const filters = {
                                        nome_fantasia: "Nome Fantasia",
                                    }

                                    for (let f in Object.values(filters)) {
                                        let filter = Object.keys(filters)[f]
                                        if (data_client[filter] === undefined || data_client[filter] === '') {
                                            return alert(`Campo obrigatório: ${filters[filter].toUpperCase()}`)
                                        }
                                    }

                                    if (logomarca !== null) {
                                        const formData = new FormData();

                                        let { _id, razao_social, cnpj, contato, consultor, contato_consultor, situacao, cliente_desde, observacao, nome_fantasia } = data_client
                                        let datToSend = { razao_social, cnpj, contato, consultor, contato_consultor, situacao, cliente_desde, observacao, nome_fantasia }

                                        formData.append('file', logomarca)
                                        for (let key in datToSend) {
                                            formData.append(key, datToSend[key])
                                        }
                                        try {

                                            if (props.edit) {
                                                // console.log(datToSend, _id)
                                                let resp = await api_bd.put(`/customers/${_id}`, formData)
                                                alert(`Cliente Editado com sucesso!\nCLIENTE\nNome: ${data_client.nome_fantasia}`)
                                                setNavigation(old => {
                                                    return ({ ...old, page_content_data: resp.data })
                                                })
                                                await props.updateData()
                                                props.callbackfn()
                                            } else {
                                                let resp = await api_bd.post('/customers', formData)
                                                alert(`Cliente cadastrado com sucesso!\nCLIENTE\nNome: ${data_client.nome_fantasia}`)
                                                await props.updateData()
                                                props.callbackfn()
                                            }

                                        } catch (error) {
                                            if (error.message.indexOf('code 400') > -1) {
                                                alert("Verifique os dados e tente novamente.")
                                            }
                                        }

                                    } else {
                                        let { _id, razao_social, cnpj, contato, consultor, contato_consultor, situacao, cliente_desde, observacao, nome_fantasia } = data_client
                                        let datToSend = { razao_social, cnpj, contato, consultor, contato_consultor, situacao, cliente_desde, observacao, nome_fantasia }
                                        try {
                                            if (props.edit) {
                                                // console.log(datToSend, _id)
                                                let resp = await api_bd.put(`/customers/${_id}`, datToSend)
                                                alert(`Cliente Editado com sucesso!\nCLIENTE\nNome: ${data_client.nome_fantasia}`)
                                                setNavigation(old => {
                                                    return ({ ...old, page_content_data: resp.data })
                                                })
                                                await props.updateData()
                                                props.callbackfn()
                                            } else {
                                                let resp = await api_bd.post('/customers', datToSend)
                                                alert(`Cliente cadastrado com sucesso!\nCLIENTE\nNome: ${data_client.nome_fantasia}`)
                                                await props.updateData()
                                                props.callbackfn()
                                            }

                                        } catch (error) {
                                            if (error.message.indexOf('code 400') > -1) {
                                                alert("Verifique os dados e tente novamente.")
                                            }
                                        }
                                    }
                                }}
                            >
                                Salvar
                            </Button>
                        </div>
                    </form>
                </CardContent>
            </Card>
        </div>
    );
}

export default FormDataClient;