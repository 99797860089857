import React, { useState, useEffect, useRef } from 'react';

import {
    Link,
    useHistory
} from "react-router-dom";

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';

import * as IoIcons from 'react-icons/io';
import EditIcon from '@material-ui/icons/Edit';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import GDriveIcon from '../assets/drive_logo.svg';

import Styles, { Fab, MiniFab, Btn, colors_access_types, primary_color } from '../components/Styles'

import {
    api_bd,
    // getUserData 
} from '../services/Api'

import FormEditClient from '../components/Forms/FormEditClient'
import FormDataTransit from '../components/Forms/FormDataTransit'
import FormDataPublicIp from '../components/Forms/FormDataPublicIp'
import FormDataAccess from '../components/Forms/FormDataAccess'
import FormEditAccess from '../components/Forms/FormEditAccess'
import FormDataSenhas from '../components/Forms/FormDataSenhas'
import FormDataObservacoes from '../components/Forms/FormDataObservacoes'
import FormDataDefaults from '../components/Forms/FormDataDefaults'
import FormDataStation from '../components/Forms/FormDataStation'
import FormDataServices from '../components/Forms/FormDataServices';
import FormDataPrefixes from '../components/Forms/FormDataPrefixes';

import ListAccessByType from '../components/ListsAccessByType'

import Autocomplete from '@material-ui/lab/Autocomplete';


import { useRecoilState, useRecoilValue } from 'recoil'
import userState, { profileData } from '../atoms/user.atom'
import clientDetailsData, { listAccess, listServices } from '../atoms/client_details.atom'
import navigationState from '../atoms/navigate_state.atom';

import Modal from '../components/Modal'

import HidePass from '../util/fns'
import ListServicesByType from '../components/ListServiceByType';


const { backend_files_address, backend_svg_files, service_colors } = require('../config.json')

function CustomerDetails(props) {
    const styles = Styles();

    const [navigation, set_navigation] = useRecoilState(navigationState)

    const [data_client, set_data_client] = useRecoilState(clientDetailsData)
    // const lista_acessos = useRecoilValue(listAccess)
    
    const lista_servicos = useRecoilValue(listServices)

    const profile_data = useRecoilValue(profileData)

    const [glpi_user_name, set_glpi_user_name] = useState('')
    const [glpi_user, set_glpi_user] = useState('')
    const [glpi_level, set_glpi_level] = useState('')

    const [show_modal, set_show_modal] = useState(false)
    const [content_modal, set_content_modal] = useState(null)
    const [document_height, set_document_height] = useState(null)

    // const [data_access, set_data_access] = useState([])
    const [data_services, set_data_services] = useState([])

    const [data_stations, set_data_stations] = useState([])

    const [type_filter_access, set_type_filter_access] = useState('Todos')
    const [type_filter_servicos, set_type_filter_servicos] = useState('Todos')
    const [filter_access, set_filter_access] = useState('')
    const [access_to_edit, set_access_to_edit] = useState({})

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)


    const [counterTypes, setCounterTypes] = useState({
        "Dados IP BGP": 0,
        "Dados IP Dedicado": 0,
        "Dados IP CDN": 0,
        "LAN Connect": 0,
        "LAN Connect IX FLEX": 0,
        "VPN L3": 0,
        "Co Location": 0,
        "Aluguel de equipamentos": 0
    })

    const menu_lateral_ref = useRef(null);

    const set_show_menu_left = () => {
        menu_lateral_ref.current.classList.toggle('menu_close')
    }


    let history = useHistory();

    const style_modal = {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        flex: 1,
        backgroundColor: '#00000055',
        left: 0,
        top: 0,
        position: 'absolute',
        float: 'left',
        minWidth: '100%',
        maxWidth: '100%',
        height: `${device_height}px`,
        maxHeight: `${device_height}px`,
        justifyContent: 'center',
        alignItems: 'flex-start',
        overflowY: "auto"

    }

    const handleClose = () => {
        set_show_modal(false);
    };


    const carregar_estacoes = async (_customerId) => {
        const lista = await api_bd.get(`/stations/${_customerId}`)
        return lista.data
    }



    const data_client_update = async (new_data) => {
        handleClose()
    }

    useEffect(() => {
        const body = document.body, html = document.documentElement;
        const total_height = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
        set_document_height(total_height)
    }, [show_modal])


    useEffect(() => {
        if (Object.keys(navigation).length > 0) {
            set_data_client(navigation.page_content_data)
            // console.log(navigation.page_content_data)
        }
    }, [navigation])


    useEffect(() => {
        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)

        return function cleanup() {
        }
    }, [])

    useEffect(() => {

        if (data_client && Object.keys(data_client).length > 0) {

            const servicos = [...data_client.servicos]
            const data2 = servicos.sort((a, b) => a.tipo.localeCompare(b.tipo))
            set_data_services(data2)

            set_glpi_user((profile_data.nivel.match(/Técnico/i) || profile_data.nivel.match(/Super/i)) ? data_client.user_sgi : profile_data.username)
            set_glpi_level(profile_data.nivel)
            set_glpi_user_name(profile_data.username)

            if (data_client.servicos && data_client.servicos.length > 0) {
                const counters = {
                    "Dados IP BGP": data_client.servicos.filter(srv => srv.tipo === "Dados IP BGP").length,
                    "Dados IP Dedicado": data_client.servicos.filter(srv => srv.tipo === "Dados IP Dedicado").length,
                    "Dados IP CDN": data_client.servicos.filter(srv => srv.tipo === "Dados IP CDN").length,
                    "LAN Connect": data_client.servicos.filter(srv => srv.tipo === "LAN Connect").length,
                    "LAN Connect IX FLEX": data_client.servicos.filter(srv => srv.tipo === "LAN Connect IX FLEX").length,
                    "VPN L3": data_client.servicos.filter(srv => srv.tipo === "VPN L3").length,
                    "Co Location": data_client.servicos.filter(srv => srv.tipo === "Co Location").length,
                    "Aluguel de equipamentos": data_client.servicos.filter(srv => srv.tipo === "Aluguel de equipamentos").length
                }

                setCounterTypes(counters)


        
            }
        }

    }, [data_client])


    useEffect(() => {
        if (lista_servicos && lista_servicos.length > 0) {
            (async () => {
                if (type_filter_servicos == "Todos") {
                    // set_data_access(lista_acessos)
                    set_data_services(lista_servicos)
                } else {
                    const new_data_services = await lista_servicos.filter((srv) => {
                        if (srv.tipo == type_filter_servicos) {
                            return srv
                        } else {
                            return false
                        }
                    })
                    set_data_services(new_data_services)
                }
            })()
        }
    }, [type_filter_servicos])



    const SelectTypeOfForm = () => {
        return (
            <Card>
                <CardContent style={{ display: 'flex', flexDirection: 'column', padding: 50 }}>
                    <span style={{ fontSize: '1.6em', fontWeight: 'bold', color: primary_color }}>O que você deseja adicionar?</span>

                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', gap: '20%', marginTop: 30 }}>
                        <div
                            className='hoverBackgroundCorlorEffect'
                            style={{ display: 'flex', width: '40%', color: '#fff', padding: 15, fontSize: '1.3em', borderRadius: 6, justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => {
                                set_content_modal('prefixes')
                            }}
                        >
                            Prefixo
                        </div>
                        <div
                            className='hoverBackgroundCorlorEffect'
                            style={{ display: 'flex', width: '40%', color: '#fff', padding: 15, fontSize: '1.3em', borderRadius: 6, justifyContent: 'center', alignItems: 'center' }}
                            onClick={() => {
                                set_content_modal('services')
                            }}
                        >
                            Serviço
                        </div>
                    </div>
                </CardContent>
            </Card>
        )
    }


    return (
        <div className="flex flexRow height_less_header" style={{ width: "100%" }}>
            {show_modal &&
                <Modal callbackfn={handleClose}>
                    {content_modal != null &&
                        (() => {
                            switch (content_modal) {
                                case 'selecttype':
                                    return <SelectTypeOfForm width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'services':
                                    return <FormDataServices width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'prefixes':
                                    return <FormDataPrefixes width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'access':
                                    return <FormDataAccess width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'pip':
                                    return <FormDataPublicIp width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'transit':
                                    return <FormDataTransit width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'senhas':
                                    return <FormDataSenhas width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'obs':
                                    return <FormDataObservacoes width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'client':
                                    return <FormEditClient width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'defaults':
                                    return <FormDataDefaults width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} />
                                    break;
                                case 'edit_access':
                                    return <FormEditAccess width={device_width < device_height ? "80%" : "50%"} callbackfn={handleClose} data_client={data_client} data_access={access_to_edit} />
                                    break;
                                case 'cad_station':
                                    return <FormDataStation width={device_width < device_height ? "80%" : "50%"} set_data_new_station={(e) => { alert(e) }} data_client={data_client} />
                                    break;
                                default:
                                    return <></>
                                    break;
                            }
                        })()
                    }
                </Modal>
            }

            <div className="height_less_header flex flexCol"
                style={{
                    padding: 30,
                    width: "100%",
                    overflowY: "auto"
                }}>
                <div>
                    <Card className={styles.card}>
                        <CardContent>
                            {show_modal === false &&
                                <div style={{ width: "100%", display: "flex", flexDirection: 'row', flexWrap: "wrap", fontSize: "0.8em", justifyContent: 'space-between' }}>
                                    <div style={{ display: "flex", flexDirection: 'row', flexWrap: "wrap" }}>
                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: service_colors["Dados IP BGP"] }}
                                            onClick={() => {
                                                set_type_filter_servicos("Dados IP BGP")
                                            }}
                                            className={type_filter_servicos == 'Dados IP BGP' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">dataset</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>{`Dados IP BGP`.toUpperCase()}</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{counterTypes["Dados IP BGP"]} ite{counterTypes["Dados IP BGP"] > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: service_colors["Dados IP Dedicado"] }}
                                            onClick={() => {
                                                set_type_filter_servicos("Dados IP Dedicado")
                                            }}
                                            className={type_filter_servicos == 'Dados IP Dedicado' ? 'animation_filter_active' : ''}
                                        >

                                            <span className="material-icons">dataset</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>{`Dados IP Dedicado`.toUpperCase()}</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{counterTypes["Dados IP Dedicado"]} ite{counterTypes["Dados IP Dedicado"] > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: service_colors["Dados IP CDN"] }}
                                            onClick={() => {
                                                set_type_filter_servicos("Dados IP CDN")
                                            }}
                                            className={type_filter_servicos == 'Dados IP CDN' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">dataset</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>{`Dados IP CDN`.toUpperCase()}</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{counterTypes["Dados IP CDN"]} ite{counterTypes["Dados IP CDN"] > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: service_colors["LAN Connect"] }}
                                            onClick={() => {
                                                set_type_filter_servicos("LAN Connect")
                                            }}
                                            className={type_filter_servicos == 'LAN Connect' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">dataset</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>{`LAN Connect`.toUpperCase()}</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{counterTypes["LAN Connect"]} ite{counterTypes["LAN Connect"] > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: service_colors["LAN Connect IX FLEX"] }}
                                            onClick={() => {
                                                set_type_filter_servicos("LAN Connect IX FLEX")
                                            }}
                                            className={type_filter_servicos == 'LAN Connect IX FLEX' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">dataset</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>{`LAN Connect IX FLEX`.toUpperCase()}</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{counterTypes["LAN Connect IX FLEX"]} ite{counterTypes["LAN Connect IX FLEX"] > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: service_colors["VPN L3"] }}
                                            onClick={() => {
                                                set_type_filter_servicos("VPN L3")
                                            }}
                                            className={type_filter_servicos == 'VPN L3' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">dataset</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>{`VPN L3`.toUpperCase()}</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{counterTypes["VPN L3"]} ite{counterTypes["VPN L3"] > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: service_colors["Co Location"] }}
                                            onClick={() => {
                                                set_type_filter_servicos("Co Location")
                                            }}
                                            className={type_filter_servicos == 'Co Location' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">dataset</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>{`Co-Location`.toUpperCase()}</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{counterTypes["Co Location"]} ite{counterTypes["Co Location"] > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>
                                        
                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: service_colors["Aluguel de equipamentos"] }}
                                            onClick={() => {
                                                set_type_filter_servicos("Aluguel de equipamentos")
                                            }}
                                            className={type_filter_servicos == 'Aluguel de equipamentos' ? 'animation_filter_active' : ''}
                                        >
                                            <span className="material-icons">dataset</span>
                                            <div style={{ marginLeft: -10, display: "flex", flexDirection: 'column', marginTop: 12 }}>
                                                <span>{`Aluguel de equipamentos`.toUpperCase()}</span>
                                                <span style={{ fontSize: '0.8em', color: '#888' }}>{counterTypes["Aluguel de equipamentos"]} ite{counterTypes["Aluguel de equipamentos"] > 1 ? 'ns' : 'm'}</span>
                                            </div>
                                        </div>

                                        <div
                                            style={{ cursor: "pointer", display: "flex", alignItems: "center", padding: 2, color: "#999999" }}
                                            onClick={() => {
                                                set_type_filter_servicos("Todos")
                                            }}
                                        >
                                            <span style={{ marginRight: 2 }} className="material-icons">remove_circle</span> {`Limpar Filtro`.toUpperCase()}
                                        </div>
                                    </div>
                                </div>
                            }
                        </CardContent>
                    </Card>

                </div>

                {Object.keys(data_client).length > 0 && data_services && data_services.length > 0 && (
                    <div style={{
                        // overflowY: "auto" 
                    }}>
                        <Card className={styles.card}>
                            <CardContent>
                                
                                <span style={{ fontSize: '1.2em', fontWeight: 'bold' }}>SERVIÇOS</span>
                                
                                <div className="flex flexCol">
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Dados IP BGP']} _situacao={true} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Dados IP Dedicado']} _situacao={true} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Dados IP CDN']} _situacao={true} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['LAN Connect']} _situacao={true} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['LAN Connect IX FLEX']} _situacao={true} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['VPN L3']} _situacao={true} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Co Location']} _situacao={true} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Aluguel de equipamentos']} _situacao={true} />

                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Dados IP BGP']} _situacao={false} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Dados IP Dedicado']} _situacao={false} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Dados IP CDN']} _situacao={false} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['LAN Connect']} _situacao={false} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['LAN Connect IX FLEX']} _situacao={false} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['VPN L3']} _situacao={false} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Co Location']} _situacao={false} />
                                    <ListServicesByType dados={data_services} customer_id={data_client._id} _filter={['Aluguel de equipamentos']} _situacao={false} />
                                </div>
                            </CardContent>
                        </Card>
                    </div>
                )}

                {Object.keys(data_client).length > 0 && data_services && data_services.length == 0 &&
                    <Card
                        className={`${styles.card} flex center-h center-v`}
                    >
                        <CardContent style={{ padding: 0 }}>
                            <h3>Nenhum Servico Cadastrado</h3>
                        </CardContent>
                    </Card>
                }
            </div>
            {glpi_level.match(/Super|Técnico/i) && (show_modal === false) &&
                <Fab
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        set_content_modal('selecttype')
                        set_show_modal(true)
                    }}
                >
                    <span className="material-icons" style={{ fontSize: 33, color: "white", marginLeft: 0 }}>note_add</span>
                </Fab>
            }
        </div>
    );
}

export default CustomerDetails;


