import { TextField } from '@material-ui/core';
import React, { useState } from 'react';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import { profileData } from '../../atoms/user.atom'
import { useSetRecoilState, useRecoilValue } from 'recoil'
import clientDetailsData from '../../atoms/client_details.atom'

import Styles, {primary_color} from '../Styles'

import api_bd from '../../services/Api';

function FormDataPrefixes(props) {

	const styles = Styles();

	const{ data_client, callbackfn } = props

	const user_sgi = useRecoilValue(profileData)

	const update_client_data = useSetRecoilState(clientDetailsData)

	const [elements, setElements] = useState([
		{
			asn: "",
			prefix: "",
			customer: ""
		}
	])


	const handle_data_form = (index, key, value) => {
		const dados = [...elements]
		dados[index][key] = value
		setElements(dados)
	}

	const deleteElement = (old) => {
		if(elements.length > 1){
			const arrIndex = elements.findIndex(el => {
				return((old.asn === el.asn) && (old.prefix === el.prefix) && (old.customer === el.customer))
			})
			const newValue = [...elements]
			newValue.splice(arrIndex, 1); 
			setElements(newValue)
		}
	}

	return (
		<Card style={{ width: '80%', padding: 20, marginBottom: 30, backgroundColor: '#fff' }}>
			<CardContent>
				{elements.map((el, index) => (
					<Card style={{ padding: 20, marginBottom: 30, backgroundColor: '#fff', position: 'relative' }}>
						<span 
							className="material-icons" 
							style={{ right: 15, color: primary_color, position: 'absolute', cursor: 'pointer' }}
							onClick={()=>{
								deleteElement(el)
							}}
						>
							cancel
						</span>
						<CardContent>
							<TextField
								className={styles.form_input}
								onChange={e => {
									handle_data_form(index, "asn", e.target.value)
								}}
								id="asn"
								label="ASN"
								required={true}
								value={elements[index].asn}
							/>
							<TextField
								className={styles.form_input}
								onChange={e => {
									handle_data_form(index, "prefix", e.target.value)
								}}
								id="prefix"
								label="Prefix"
								required={true}
								value={elements[index].prefix}
							/>
							<TextField
								className={styles.form_input}
								onChange={e => {
									handle_data_form(index, "customer", e.target.value)
								}}
								id="customer"
								label="Customer"
								required={true}
								value={elements[index].customer}
							/>
						</CardContent>
					</Card>
				))}

				<div
					className='hoverBackgroundCorlorEffect'
					style={{ display: 'flex', width: 210, color: '#fff', marginBottom: 30, padding: 15, fontSize: '1.2em', borderRadius: 6, justifyContent: 'center', alignItems: 'center' }}
					onClick={() => {
						const dados = [...elements]
						dados.push({
							asn: "",
							prefix: "",
							customer: ""
						})
						setElements(dados)
					}}
				>
					Adicionar campos
				</div>

				<div
					className='hoverBackgroundCorlorEffect'
					style={{ display: 'flex', width: 210, color: '#fff', padding: 15, fontSize: '1.2em', borderRadius: 6, justifyContent: 'center', alignItems: 'center' }}
					onClick={async () => {
						// console.log(elements)
						// console.log(data_client)
						// console.log(user_sgi)

						for (let i in elements){
							let {asn, prefix, customer} = elements[i]
							if( (asn === '') || (prefix === '') || (customer === '') ){
								return (alert('Todos os campos são obrigatórios'))
							}
						}

						try {
							const resp = await api_bd.put(`/customers/addprefixes/${data_client._id}`, {
								prefixData: elements,
								userData: user_sgi 
							})
							update_client_data(resp.data)
							callbackfn(resp.data)
							alert(`Dados inseridos com sucesso!`)
						} catch (error) {
							if (error.message.indexOf('code 400') > -1) {
								alert("Verifique os dados e tente novamente.")
							}
						}
					}}
				>
					Salvar
				</div>
			</CardContent>
		</Card>
	);
}

export default FormDataPrefixes;