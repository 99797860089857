import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Login from './pages/Login';
import Logout from './pages/Logout';
// import Home from './pages/Home';
import CadUser from './pages/CadUser';
import CadClient from './pages/CadClient';
import ClientDetails from './pages/ClientDetails';
// import NavigateTopSeacrh from './pages/NavigateTopSearch';
import NetworkMap from './pages/NetworkMap';
import Suport_Calendar from './pages/Suport_Calendar';
import PageTickets from './pages/PageTickets';
import RoutePrivate from './components/RoutePrivate';

import RelatorioImplantacao from './components/FlowNodesComponent/Relatorio';


import ProjectsPage from './pages/Projects';


import Home from './pages/Home';


// import LeftSideBar from './components/SideBars/LeftSideBar'

import Stations from './pages/Stations';

import { useRecoilValue } from 'recoil'
import userState, { profileData } from './atoms/user.atom'

// import { getLoginToken, setLoginToken } from './services/Api'

function App(props) {
	const profile_data = useRecoilValue(profileData) || {}
	return (
		<Router>
			{Object.keys(profile_data).length > 0 ? <Navbar /> : <></>}
			<Switch>
				<Route path='/login' exact component={Login} />
				<RoutePrivate path='/' exact component={Home} />
				<RoutePrivate path='/ch/:idticket' exact component={Home} />
				<RoutePrivate path='/caduser' component={CadUser} />
				<RoutePrivate path='/cadclient' component={CadClient} />
				<RoutePrivate path='/clientdetails' component={ClientDetails} />
				<RoutePrivate path='/stations' component={Stations} />
				<RoutePrivate path='/tickets' component={PageTickets} />
				<RoutePrivate path='/suport' component={Suport_Calendar} />
				<RoutePrivate path='/networkmap' component={NetworkMap} />
				<RoutePrivate path='/projects' component={ProjectsPage} />
				<RoutePrivate path='/logout' component={Logout} />
				<RoutePrivate path='/relatorio-i' component={RelatorioImplantacao} />
				<RoutePrivate path='*' component={Home} />
			</Switch>
		</Router>
	);
}

export default App;



