import React, { useState, useEffect } from 'react';

import { Card } from '@material-ui/core';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';
// import Badge from '@material-ui/core/Badge';

import { Link } from 'react-router-dom';
// import * as IoIcons from 'react-icons/io';
import Styles, { Fab, LineMenuHome } from '../Styles'

import {
	api_bd,
	api_glpi
} from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import userState, { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'
import clientDetails from '../../atoms/client_details.atom'
// import userTickets from '../../atoms/tickets.atom'
import navigationState from '../../atoms/navigate_state.atom'

import ListTickets from '../Tables/Tickets'

import '../Navbar.css';

import AvisoHome from '../../assets/aviso_home.jpeg'

import FormDataClient from '../Forms/FormDataClient';

import Modal from '../Modal';

// import LogoColor from '../../assets/logo_big_intec.png'
// import FormDataUser from '../Forms/FormDataUser'
// import ModalTikets from '../ModalTickets'

const { backend_files_address } = require('../../config.json')

function CustomerList(props) {
	const styles = Styles();

	const [sgi_user, set_sgi_user] = useRecoilState(userState)

	const profile_data = useRecoilValue(profileData)

	const set_client_details = useSetRecoilState(clientDetails)

	const [data_home, set_data_home] = useRecoilState(customerData)

	const [navigation, set_navigation] = useRecoilState(navigationState)

	const [glpi_level, set_glpi_level] = useState('')

	const [device_height, set_device_height] = useState(null)

	const [loading_message, set_loading_message] = useState('Carregando informações, por favor aguarde.....')

	const [showModal, setShowModal] = useState(false)

	const [contentModal, setContentModal] = useState(null)
	// const [show_loading, set_show_loading] = useState(true)
	// const [showModal_tickets, setShowModal_tickets] = useState(false)
	// const [device_width, set_device_width] = useState(null)
	// const [tickets, set_tickets] = useRecoilState(userTickets)
	// const [glpi_user_name, set_glpi_user_name] = useState('')
	// const [glpi_user, set_glpi_user] = useState('')
	// const [user_sgi, set_user_sgi] = useState(null)


	const loadCustomers = async () => {
		set_loading_message('Carregando informações, por favor aguarde.....')
		const dados = await api_bd.get(`/customers`)
		if (dados.data.length > 0) {
			const ordered_data = dados.data //? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
			// set_data_home(dados.data)
			set_data_home(ordered_data)
		} else {
			set_loading_message('Nenhuna Informação Cadastrada')
		}
	}


	useEffect(() => {
		(async () => {
			await loadCustomers()
		})()

		set_client_details({})
		// const dw = document.documentElement.clientWidth
		const dh = document.documentElement.clientHeight
		// set_device_width(dw)
		set_device_height(dh)

	}, [])


	useEffect(() => {
		// console.log('Page HOME', profile_data, sgi_user)
		// console.log('ProfileData', profile_data)
		// console.log('UserData', sgi_user)
		set_glpi_level(profile_data.nivel)
	}, [sgi_user])


	useEffect(() => {
		(async () => {
			set_loading_message('Carregando informações, por favor aguarde.....')
			const dados = await api_bd.get(`/customers`)
			if (dados.data.length > 0) {
				const ordered_data = dados.data //? await dados.data.sort((a, b) => a.nome.localeCompare(b.nome)) : []
				// set_data_home(dados.data)
				set_data_home(ordered_data)
			} else {
				set_loading_message('Nenhuna Informação Cadastrada')
			}
		})()
	}, [glpi_level])


	useEffect(() => {
		if (contentModal === null) {
			setShowModal(false)
		} else {
			setShowModal(true)
		}
	}, [contentModal])


	return (
		<div className="flex flexRow height_less_header" style={{ width: "100%" }}>
			<>
				{data_home.length == 0 &&
					<div style={{
						display: "flex",
						flexWrap: "wrap",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						width: "100%"
					}}
						className="height_less_header"
					>
						<div className="flex" style={{ justifyContent: 'center', alignItems: 'center', width: '80%', maxWidth: '80%', height: '20vh', maxHeight: '20vh' }}>
							<div className="loader" style={{ width: 50, height: 50, marginRight: 10 }}></div>
							<h2>{loading_message}</h2>
						</div>

					</div>
				}

				{showModal &&
					<Modal callbackfn={() => {
						setContentModal(null)
					}}>
						{contentModal}
					</Modal>
				}

				{data_home.length > 0 &&
					<div style={{
						position: 'relative',
						// display: "flex",
						// flexWrap: "wrap",
						// flexDirection: 'row',
						// justifyContent: 'flex-start',
						// alignItems: 'flex-start',
						width: "100%"
					}}
						className="height_less_header"
					>
						<div style={{ maxHeight: 50, paddingLeft: 30, marginTop: 20, marginBottom: 10, width: "100%" }}>
							<h3>LISTA DE CLIENTES</h3>
						</div>
						<div
							// className="flex flexWrap height_less_header" 
							style={{
								flexDirection: 'row',
								flexWrap: 'wrap',
								display: 'flex',
								alignItems: 'flex-start',


								overflowY: "auto",
								paddingBottom: 70,
								width: "100%",
								// height: '100%',
								gap: '2%'
							}}>
							{data_home.length > 0 && data_home.map((el) => ( el.situacao === 'ativo' &&
								<Card key={el._id} className={styles.card} style={{ width: '46%', height: 50 }}
									onClick={() => {
										set_navigation({
											page_content: 'customer_detail',
											page_content_data: el
										})
									}}
								>
									<CardContent className="flex flexCol justify-content-sb" style={{ height: "100%" }} >
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<div>
												<img
													style={{ borderRadius: 5, width: 50, height: 50 }}
													className={styles.imgCard}
													src={el.logomarca.filename == "" ? `${backend_files_address}/default-logo.png` : `${backend_files_address}/${el.logomarca.filename}`}
												/>
											</div>
											<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
												<span><b>Nome Fantasia:</b> {el.nome_fantasia}</span>
												<span><b>Razão Social:</b> {el.razao_social}</span>
											</div>
										</div>
									</CardContent>
								</Card>
							))}

							{data_home.length > 0 && data_home.map((el) => (  el.situacao !== 'ativo' &&
								<Card key={el._id} className={styles.card} style={{ width: '46%', height: 50 }}
									onClick={() => {
										set_navigation({
											page_content: 'customer_detail',
											page_content_data: el
										})
									}}
								>
									<CardContent className="flex flexCol justify-content-sb" style={{ height: "100%", opacity: 0.3 }} >
										<div style={{ display: 'flex', flexDirection: 'row' }}>
											<div>
												<img
													style={{ borderRadius: 5, width: 50, height: 50 }}
													className={styles.imgCard}
													src={el.logomarca.filename == "" ? `${backend_files_address}/default-logo.png` : `${backend_files_address}/${el.logomarca.filename}`}
												/>
											</div>
											<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
												<span><b>Nome Fantasia:</b> {el.nome_fantasia}</span>
												<span><b>Razão Social:</b> {el.razao_social}</span>
											</div>
										</div>
									</CardContent>
								</Card>
							))}






							{/* Botão Cadastro Cliente */}
							<Fab alt="Adicionar Cliente" className="flex center-h center-v"
								onClick={() => {
									setContentModal(<FormDataClient
										updateData={loadCustomers}
										callbackfn={() => {
											setContentModal(null)
										}} />)
									// FormDataClient
								}}
							>
								<span className="material-icons" style={{ fontSize: 33, color: "white", marginLeft: -3 }}>person_add</span>
							</Fab>
						</div>
					</div>
				}
			</>
		</div>

	);
}

export default CustomerList;


