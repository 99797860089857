import React, {
    useState,
    useEffect
} from 'react';

import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// import Checkbox from '@material-ui/core/Checkbox';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles, { primary_color } from '../../components/Styles'

// import FileUploader from '../FileUploader'
// import FileDropzone from '../DropZone';

import Toast from '../Toast'

import { api_bd } from '../../services/Api'

import {
    // useRecoilState, 
    useRecoilValue,
    useSetRecoilState
} from 'recoil'
import { profileData } from '../../atoms/user.atom'
import navigationState from '../../atoms/navigate_state.atom'


import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css';

// import Autocomplete from '@material-ui/lab/Autocomplete';


function FormDataTickets(props) {
    const { data_client, width, data_client_update } = props
    const styles = Styles();
    const loggedUser = useRecoilValue(profileData)

    const [show_toast, set_show_toast] = useState(false)
    const [content_toast, set_content_toast] = useState(null)

    const [form_data, set_form_data] = useState({
        // tipo_solicitacao: 'selecione',
        // categoria: 'selecione',
        prioridade: 'padrão',
        // situacao: '',
        // subcategoria: 'selecione',
        // reincidente: '',
        // nome: '',
        // cliente: '',
        // fornecedor: '',
        // tipo: 'selecione',
        // tipo_ativo: 'selecione',
        // tipo_alteracao: 'selecione',
        // servico: '',
        // situacao_do_servico: 'selecione',
        // ponto_a: '',
        // ponto_b: '',
        // as_remoto: '',
        // asn_ou_prefixo: '',
        // cliente_as_ou_prefixo: '',
        // usuario: '',
        // senha: '',
        // localidade: '',
        // descricao_local: '',
        // ip: '',
        // ip_origem: '',
        // ip_destino: '',
        // ip_cdn: '',
        // vlan_e_ip: '',
        // quantidade_ips: '',
        // banda_contratada: '',
        // nova_banda: '',
        // designador: '',
        // fabricante_e_modelo: '',
        // tracerouter: '',
        // url: '',
        // info_cliente_1: '',
        // info_cliente_2: '',
        titulo: '',
        detalhes_solicitacao: '',
        // anexo: false,
        // indisponibilidade: 'Não'
    })
    const [anexo, set_anexo] = useState(false);

    // const [submit_is_disabled, set_submit_is_disabled] = useState(false)

    const [device_height, set_device_height] = useState(null)
    const [device_width, set_device_width] = useState(null)

    const [condicoes, set_condicoes] = useState({})

    const set_navigation_state = useSetRecoilState(navigationState)

    const [list_customers, set_list_customers] = useState([])

    const [requerente, set_requerente] = useState({ nome: '', id: '' })

    const load_users = async () => {
        const lista = await api_bd.get('/users')
        const alphabetically = lista.data.filter(user => user.ativo === true).sort((a, b) => a.nome.localeCompare(b.nome))
        set_list_customers([{ _id: loggedUser.id, nome: loggedUser.nome }, ...alphabetically.filter(user => (user.nivel.match(/Cliente/)))])
    }

    const form_labels = {
        tipo_solicitacao: 'tipo de solicitação',
        categoria: 'categoria',
        prioridade: 'prioridade',
        subcategoria: 'subcategoria',
        reincidente: 'reincidente',
        nome: 'nome',
        cliente: 'cliente',
        fornecedor: 'fornecedor',
        tipo: 'tipo',
        tipo_ativo: 'tipo de ativo',
        tipo_alteracao: 'tipo de alteração',
        servico: 'serviço',
        situacao_do_servico: 'situação do serviço',
        ponto_a: 'ponto a',
        ponto_b: 'ponto b',
        as_remoto: 'as remoto',
        asn_ou_prefixo: 'ASN/Prefixo',
        cliente_as_ou_prefixo: 'Cliente AS/Prefixo',
        usuario: 'usuário',
        senha: 'senha',
        localidade: 'localidade',
        descricao_local: 'descrição da localidade',
        ip: 'ip',
        ip_origem: 'ip de origem',
        ip_destino: 'ip de destino',
        ip_cdn: 'ip do cdn',
        vlan_e_ip: 'vlan e ip',
        quantidade_ips: 'quantidade de ips',
        banda_contratada: 'banda contratada',
        nova_banda: 'nova banda',
        designador: 'designador',
        fabricante_e_modelo: 'fabricante e modelo',
        tracerouter: 'tracerouter',
        url: 'url',
        info_cliente_1: 'informação do cliente 1',
        info_cliente_2: 'informação do cliente 2',
        titulo: 'título',
        detalhes_solicitacao: 'detalhes da solicitação',
        anexo: 'anexo',
        indisponibilidade: 'indisponibilidade'
    }

    //form inputs exibition
    const handle_condition = () => {
        return new Promise((resolve, reject) => {
            const data = {
                tipo_solicitacao: false,//true,

                categoria: false,//true,

                prioridade: true,

                subcategoria: false,//(form_data.categoria === 'ativo_de_rede') ? true : false,

                reincidente: false,//(form_data.tipo_solicitacao === 'suporte_provedor' || form_data.tipo_solicitacao === 'servico_cliente_provedor') ? true : false,

                nome: false,

                cliente: false,//(form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'liberacao_prefixo' || form_data.categoria === 'suporte' || form_data.categoria === 'upgrade_downgrade') ? true : false,

                fornecedor: false,//(form_data.categoria === 'link') ? true : false,

                tipo: false,//(form_data.categoria === 'ativacao_transporte_transito' || form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'liberacao_prefixo' || form_data.categoria === 'suporte' || form_data.categoria === 'upgrade_downgrade') ? true : false,

                tipo_ativo: false,//(form_data.categoria === 'ativo_de_rede') ? true : false,

                tipo_alteracao: false,//(form_data.categoria === 'ativo_de_rede' && form_data.subcategoria === 'info_alteracao') ? true : false,

                servico: false,//(form_data.categoria === 'falha_acesso_servico') ? true : false,

                situacao_do_servico: false,//(form_data.categoria === 'falha_acesso_servico') ? true : false,

                ponto_a: false,//(form_data.categoria === 'ativacao_transporte_transito' || form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'suporte' || form_data.categoria === 'transporte' || form_data.categoria === 'upgrade_downgrade') && (form_data.tipo === 'transporte') ? true : false,
                ponto_b: false,//(form_data.categoria === 'ativacao_transporte_transito' || form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'suporte' || form_data.categoria === 'transporte' || form_data.categoria === 'upgrade_downgrade') && (form_data.tipo === 'transporte') ? true : false,

                as_remoto: false,//(form_data.tipo === 'link') ? true : false,

                asn_ou_prefixo: false,//(form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'liberacao_prefixo' || form_data.categoria === 'suporte' || form_data.categoria === 'upgrade_downgrade') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn') ? true : false,

                cliente_as_ou_prefixo: false,//(form_data.categoria === 'ativacao' || form_data.categoria === 'liberacao_prefixo') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn') ? true : false,

                usuario: false,//(form_data.categoria === 'vpn') ? true : false,
                senha: false,//(form_data.categoria === 'vpn') ? true : false,

                localidade: false,//(form_data.categoria === 'olt' || form_data.categoria === 'roteador' || form_data.categoria === 'servidor' || form_data.categoria === 'switch' || form_data.categoria === 'ativacao' || form_data.categoria === 'desativacao' || form_data.categoria === 'liberacao_prefixo' || form_data.categoria === 'ativo_de_rede' || form_data.categoria === 'falha_massiva' || form_data.categoria === 'vpn' || form_data.categoria === 'ativacao_transporte_transito') && (form_data.tipo !== 'transporte') ? true : false,

                descricao_local: false,//(form_data.categoria === 'olt' || form_data.categoria === 'roteador' || form_data.categoria === 'servidor' || form_data.categoria === 'switch' || form_data.categoria === 'ativo_de_rede') ? true : false,

                ip: false,//(form_data.categoria === 'suporte' || form_data.categoria === 'servidor' || form_data.categoria === 'ativo_de_rede' || form_data.categoria === 'upgrade_downgrade') && (form_data.tipo_solicitacao === 'suporte_provedor' || form_data.tipo === 'dedicado') && (form_data.tipo_solicitacao === 'suporte_provedor' || form_data.subcategoria === 'info_alteracao') ? true : false,

                ip_origem: false,//(form_data.categoria === 'falha_acesso_servico' || form_data.categoria === 'suporte') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn' || form_data.tipo === 'dedicado') ? true : false,
                ip_destino: false,//(form_data.categoria === 'falha_acesso_servico' || form_data.categoria === 'suporte') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn' || form_data.tipo === 'dedicado') ? true : false,

                ip_cdn: false,//(form_data.categoria === 'suporte' && form_data.tipo === 'cdn') ? true : false,

                vlan_e_ip: false,//(form_data.tipo === 'link') ? true : false,

                quantidade_ips: false,//(form_data.categoria === 'pool_ip_clientes') ? true : false,

                banda_contratada: false,//(form_data.categoria === 'ativacao_transporte_transito' || form_data.categoria === 'ativacao') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn' || form_data.tipo === 'transporte' || form_data.tipo === 'link') ? true : false,

                nova_banda: false,//(form_data.categoria === 'upgrade_downgrade') ? true : false,

                designador: false,//(form_data.categoria === 'ativacao_transporte_transito') ? true : false,

                fabricante_e_modelo: false,//(form_data.categoria === 'olt' || form_data.categoria === 'roteador' || form_data.categoria === 'servidor' || form_data.categoria === 'switch' || form_data.categoria === 'ativo_de_rede') ? true : false,

                tracerouter: false,// (form_data.categoria === 'suporte') && (form_data.tipo === 'bgp' || form_data.tipo === 'cdn' || form_data.tipo === 'dedicado') ? true : false,

                url: false,//(form_data.categoria === 'falha_acesso_servico' || form_data.categoria === 'servidor') ? true : false,

                info_cliente_1: false,//(form_data.categoria === 'falha_massiva') ? true : false,
                info_cliente_2: false,//(form_data.categoria === 'falha_massiva') ? true : false,

                titulo: true,

                detalhes_solicitacao: true,
                anexo: (form_data.nome !== '') ? true : false,

                indisponibilidade: false
            }
            resolve(data)
        })
    }



    const handle_form_data = (key, value) => {
        let data = { ...form_data }
        data[key] = value
        if (key === 'tipo_solicitacao') {
            data["categoria"] = 'selecione'
            data["subcategoria"] = 'selecione'
            data["tipo"] = 'selecione'
            data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'categoria') {
            // data["categoria"] = 'selecione'
            data["subcategoria"] = 'selecione'
            data["tipo"] = 'selecione'
            data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'subcategoria') {
            // data["categoria"] = 'selecione'
            // data["subcategoria"] = 'selecione'
            data["tipo"] = 'selecione'
            data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'tipo') {
            // data["categoria"] = 'selecione'
            // data["subcategoria"] = 'selecione'
            // data["tipo"] = 'selecione'
            data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'tipo_ativo') {
            // data["categoria"] = 'selecione'
            // data["subcategoria"] = 'selecione'
            // data["tipo"] = 'selecione'
            // data["tipo_ativo"] = 'selecione'
            data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        } else if (key === 'tipo_alteracao') {
            // data["categoria"] = 'selecione'
            // data["subcategoria"] = 'selecione'
            // data["tipo"] = 'selecione'
            // data["tipo_ativo"] = 'selecione'
            // data["tipo_alteracao"] = 'selecione'
            data["situacao_do_servico"] = 'selecione'
        }
        set_form_data(data)
    }

    const zero_form = () => {
        // set_anexo(false)
        const zerodata = {
            // tipo_solicitacao: 'selecione',
            // categoria: 'selecione',
            prioridade: 'padrão',
            // situacao: '',
            // subcategoria: 'selecione',
            // reincidente: '',
            // nome: '',
            // cliente: '',
            // fornecedor: '',
            // tipo: 'selecione',
            // tipo_ativo: 'selecione',
            // tipo_alteracao: 'selecione',
            // servico: '',
            // situacao_do_servico: 'selecione',
            // ponto_a: '',
            // ponto_b: '',
            // as_remoto: '',
            // asn_ou_prefixo: '',
            // cliente_as_ou_prefixo: '',
            // usuario: '',
            // senha: '',
            // localidade: '',
            // descricao_local: '',
            // ip: '',
            // ip_origem: '',
            // ip_destino: '',
            // ip_cdn: '',
            // vlan_e_ip: '',
            // quantidade_ips: '',
            // banda_contratada: '',
            // nova_banda: '',
            // designador: '',
            // fabricante_e_modelo: '',
            // tracerouter: '',
            // url: '',
            // info_cliente_1: '',
            // info_cliente_2: '',
            titulo: '',
            detalhes_solicitacao: '',
            // anexo: false,
            // indisponibilidade: 'Não'
        }
        set_form_data({ ...zerodata })
    }

    useEffect(() => {
        (async () => {
            await load_users()
        })()


        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)

        if (loggedUser.nivel.match(/Super|Técnico/i)) {
            set_requerente({ nome: loggedUser.nome, id: loggedUser.id })
            //console.log('Logged User: ', loggedUser)
        }
    }, [])


    useEffect(() => {
        (async () => {
            const data_cond = await handle_condition()
            // console.log(data_cond)
            set_condicoes(data_cond)
            // console.log('Form Data: ', form_data)
            // console.log('Condições: ', data_cond)
        })()
    }, [form_data])


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flex: 1,
            left: 0,
            top: 0,
            maxWidth: '90%',
            height: device_height - 50,
            maxHeight: device_height - 50,
            justifyContent: 'center',
            alignItems: 'flex-start',
            overflowY: "auto"
        }}>
            <div className={styles.div_root_component} style={{ width, zIndex: 1 }}>
                {show_toast &&
                    <Toast _style={{ backgroundColor: '#3c5ca6cc', color: 'white' }}>{content_toast}</Toast>
                }
                <Card
                    style={{
                        margin: 10,
                        minWidth: 150,
                        minHeight: 75,
                        color: primary_color
                    }}>
                    <CardContent>
                        <CardHeader
                            title="NOVO CHAMADO"
                            subheader=""
                        />
                        {/* <form className={styles.form_cad_user} noValidate autoComplete="off"> */}
                        <form style={{
                            display: "flex",
                            flexDirecion: "row",
                            flexWrap: "wrap",
                            width: (device_width - 214) * 0.7,
                            minWidth: (device_width - 214) * 0.7,
                            maxWidth: (device_width - 214) * 0.7,
                            zIndex: 99999
                        }} noValidate autoComplete="off">
                            {list_customers && list_customers.length > 0 && loggedUser.nivel.match(/Super|Técnico/i) &&
                                <div style={{ width: "100%" }}>
                                    <FormControl className={styles.form_input} style={{ marginTop: 15 }}>
                                        <InputLabel htmlFor="select-requerente">Requerente &nbsp; &nbsp;  *</InputLabel>
                                        <Select
                                            value={`${requerente.nome}|${requerente.id}`}
                                            defaultValue={`${loggedUser.nome}|${loggedUser.id}`}
                                            onChange={e => {
                                                const [nome, id] = e.target.value.split('|')
                                                set_requerente({ nome: `${nome}`, id })
                                            }}
                                        >
                                            {list_customers.map(el => (<MenuItem key={el._id} value={`${el.nome}|${el._id}`}>{el.nome}</MenuItem>))}
                                        </Select>
                                    </FormControl>
                                </div>
                            }

                            {condicoes.prioridade &&
                                <div style={{ width: "100%", display: 'flex', flexDirection: 'row', borderBottom: '1px #888888 solid' }}>
                                    <div style={{
                                        color: "#888888",
                                        marginLeft: 0,
                                        marginTop: 18,
                                        marginBottom: 5,
                                        top: 10
                                    }}>Prioridade: </div>
                                    <div style={{
                                        paddingLeft: 5,
                                        color: "#888888",
                                        marginLeft: 0,
                                        marginTop: 16,
                                        top: 10
                                    }}>
                                        <span style={{ margin: 2, fontSize: '1.1em', cursor: 'pointer', color: form_data.prioridade === 'padrão' ? "#888888" : form_data.prioridade === 'baixa' ? "#228B22" : form_data.prioridade === 'média' ? "#ff7900" : "#ff0000" }}
                                            class="material-icons"
                                            onClick={() => {
                                                handle_form_data("prioridade", 'baixa')
                                            }}
                                        >warning</span>
                                        <span style={{ margin: 2, fontSize: '1.1em', cursor: 'pointer', color: form_data.prioridade === 'média' ? "#ff7900" : form_data.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                            class="material-icons"
                                            onClick={() => {
                                                handle_form_data("prioridade", 'média')
                                            }}
                                        >warning</span>
                                        <span style={{ margin: 2, fontSize: '1.1em', cursor: 'pointer', color: form_data.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                            class="material-icons"
                                            onClick={() => {
                                                handle_form_data("prioridade", 'alta')
                                            }}
                                        >warning</span>
                                    </div>
                                </div>
                            }


                            {condicoes.titulo &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        onChange={e => {
                                            if (e.target.value.length > 200) { return false }
                                            handle_form_data("titulo", e.target.value)
                                        }}
                                        id="titulo"
                                        label="Título"
                                        inputProps={{ placeholder: "Digite uma descrição resumida da solicitação" }}
                                        required={true} value={form_data?.titulo}
                                    />
                                </div>
                            }

                            {condicoes.detalhes_solicitacao &&
                                <div style={{ width: "100%" }}>
                                    <TextField
                                        className={styles.form_input}
                                        multiline
                                        rows={10}
                                        onChange={e => {
                                            handle_form_data("detalhes_solicitacao", e.target.value)
                                        }}
                                        id="detalhes_solicitacao"
                                        label="Detalhes da solicitação"
                                        inputProps={{ placeholder: "Digite uma descrição completa e detalhada da solicitação" }}
                                        required={true}
                                        value={form_data?.detalhes_solicitacao}
                                    />
                                </div>
                            }


                            <div style={{ width: "100%" }}>
                                <Button
                                    className={styles.btn_submit}
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    disabled={show_toast}
                                    onClick={async () => {
                                        set_content_toast(() => (
                                            <div className="flex" style={{ justifyContent: 'center', alignItems: 'center', width: '100%', maxWidth: '100%', height: 70, maxHeight: 70 }}>
                                                <div className="loader" style={{ width: 40, height: 40, marginRight: 10 }}></div>
                                                <h3>Aguarde, enviando dados...</h3>
                                            </div>
                                        ))
                                        set_show_toast(true)

                                        const data_to_send = {
                                            customer_id: loggedUser.nivel.match(/Super|Técnico/i) ? requerente.id : loggedUser.nivel === 'Cliente-Admin' ? loggedUser.conta_master.masterUserId : loggedUser.id,//ID do uduário que abriu o chamado
                                            customer_name: loggedUser.nivel.match(/Super|Técnico/i) ? requerente.nome : loggedUser.nivel === 'Cliente-Admin' ? loggedUser.conta_master.masterUserName : loggedUser.nome,
                                            situacao: { titulo: 'novo', descricao: '' },
                                            // indisponibilidade: form_data.indisponibilidade
                                        }

                                        for (let k in form_data){
                                            if(form_data[k] && form_data[k] !== ''){
                                                data_to_send[k] = form_data[k]
                                            }
                                        }

                                        try {
                                            let resp = null
                                            
                                            resp = await api_bd.post('/tickets', data_to_send)
                                            .catch(e => {
                                                console.log(Object.keys(e))
                                                alert(e?.response?.data || e.message)
                                            })
                                            set_show_toast(false)

                                            confirmAlert({
                                                customUI: ({ onClose }) => {
                                                    return (
                                                        <div>
                                                            <p style={{ fontSize: '1.4em', margin: 5 }}>Dados inseridos com sucesso!</p>
                                                            <p style={{ fontSize: '1.2em', margin: 5 }}><b>ID: </b>{resp.data.cod_id < 10 ? '000' + resp.data.cod_id : resp.data.cod_id < 100 ? '00' + resp.data.cod_id : resp.data.cod_id < 1000 ? '0' + resp.data.cod_id : resp.data.cod_id}</p>
                                                            <p style={{ fontSize: '1.2em', margin: 5 }}><b>Título: </b>{data_to_send.titulo}</p>
                                                            <p style={{ fontSize: '1.2em', marginLeft: 5, marginRight: 5, marginTop: 15, marginBottom: 5 }}><b>Deseja abrir um novo chamado?</b></p>
                                                            <button style={{ fontSize: '1.1em', margin: 3, padding: 5 }} onClick={() => {
                                                                zero_form()
                                                                onClose();
                                                            }}>Sim</button>

                                                            <button style={{ fontSize: '1.1em', marginLeft: 10, marginRight: 5, marginTop: 5, marginBottom: 5, padding: 5 }} onClick={() => {
                                                                zero_form()
                                                                onClose();
                                                                set_navigation_state({ page_content: 'listtickets' })
                                                            }}>Não</button>
                                                        </div>
                                                    );
                                                }
                                            });

                                        } catch (error) {
                                            if (error.message.indexOf('code 400') > -1) {
                                                alert("Verifique os dados e tente novamente.")
                                            }
                                        }
                                    }}
                                >
                                    Salvar
                                </Button>
                            </div>
                        </form>
                    </CardContent>
                </Card>
            </div>
        </div >
    );
}

export default FormDataTickets;