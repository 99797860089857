import React, {
    useState,
    useEffect
} from 'react';

// import { makeStyles } from '@material-ui/core/styles';
// import FormHelperText from '@material-ui/core/FormHelperText';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
// import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
// import Button from '@material-ui/core/Button';
// import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
// import Checkbox from '@material-ui/core/Checkbox';


import Autocomplete from '@material-ui/lab/Autocomplete';


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


import Card from '@material-ui/core/Card';
// import CardHeader from '@material-ui/core/CardHeader';
import CardContent from '@material-ui/core/CardContent';

import Styles from '../Styles'

import { api_bd } from '../../services/Api'

import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil'
import navigationState from '../../atoms/navigate_state.atom'
import { profileData } from '../../atoms/user.atom'
import customerData from '../../atoms/customers.atom'

import NivelDeAcesso from '../NivelDeAcesso'

import CardsContadores from '../CardsContadores.js';

const { tipos_situacao, cor_tipos_situacao } = require('../../config.json')

function TableChamados(props) {

    const styles = Styles();

    const loggedUser = useRecoilValue(profileData)

    const lista_clientes = useRecoilValue(customerData)

    const [lista_chamados, set_lista_chamados] = useState([])

    const [device_height, set_device_height] = useState(null)

    const [device_width, set_device_width] = useState(null)

    const [loading_message, set_loading_message] = useState('Aguarde, carregando lista de chamados.....')

    const set_navigattion_state = useSetRecoilState(navigationState)

    const [filtro_situacao, set_filtro_situacao] = useState(localStorage.getItem('filtro_situacao') || 'em_aberto')
    const [filtro_tipo_solicitacao, set_filtro_tipo_solicitacao] = useState(localStorage.getItem('filtro_tipo_solicitacao') || 'todos')
    const [filtro_pesquisa, set_filtro_pesquisa] = useState(localStorage.getItem('filtro_pesquisa') || '')
    const [filtro_cliente, set_filtro_cliente] = useState(localStorage.getItem('filtro_cliente') || 'TODOS')
    const [filtro_tecnico, set_filtro_tecnico] = useState(localStorage.getItem('filtro_tecnico') || 'TODOS')

    const [list_of_reverse_ID, set_list_of_reverse_ID] = useState(false)
    const [list_of_reverse_alphabetical_title, set_list_of_reverse_alphabetical_title] = useState(false)

    const [qtd_list_chamados, set_qtd_list_chamados] = useState([])

    const [list_users, set_list_users] = useState([])

    const [show_lines_filtered, set_show_lines_filtered] = useState(0)

    const [enabled_getforme, set_enabled_getforme] = useState(true)

    const [modo_monitor, set_modo_monitor] = useState(false)

    const formatDate = (date_str) => {
        const date = new Date(date_str);  // dateStr you get from mongodb
        const dia = date.getDate();
        const mes = date.getMonth() + 1;
        const ano = date.getFullYear();

        const hora = date.getHours();
        const minuto = date.getMinutes();

        return { dia, mes, ano, hora, minuto }
    }


    const load_users = async () => {
        const lista = await api_bd.get('/users')
        set_list_users(lista.data.filter(user => (user.nivel.match(/Super|Técnico/) && user.ativo === true)).sort((a, b) => a.nome.localeCompare(b.nome)))
    }

    function notifyMe(_title, _notificObject) {
        // Verifica se o browser suporta notificações
        if (!("Notification" in window)) {
            alert("Este browser não suporta notificações de Desktop");
        }

        // Let's check whether notification permissions have already been granted
        else if (Notification.permission === "granted") {
            // If it's okay let's create a notification
            var notification = new Notification(_title, _notificObject);
        }

        // Otherwise, we need to ask the user for permission
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission(function (permission) {
                // If the user accepts, let's create a notification
                if (permission === "granted") {
                    var notification = new Notification(_title, _notificObject);
                }
            });
        }

        // At last, if the user has denied notifications, and you
        // want to be respectful there is no need to bother them any more.
    }


    // const cor_situacao = (situacao) => {
    //     if (situacao.match(/novo/i)) { return '#469536' }
    //     if (situacao.match(/atribuido/i)) { return '#B0C4DE' }
    //     if (situacao.match(/em_andamento/i)) { return '#90EE90' }
    //     if (situacao.match(/pendente/i)) { return '#FABA25' }
    //     if (situacao.match(/solucionado/i)) { return '#C0C0C0' }
    //     if (situacao.match(/encerrado_inatividade/i)) { return '#FF7F50' }
    //     if (situacao.match(/cancelado/i)) { return '#BA3A3A' }
    //     if (situacao.match(/fechado/i)) { return '#1C1C1C' }
    //     if (situacao.match(/aguardando/i)) { return '#964594' }
    //     if (situacao.match(/janela_agendada/i)) { return '#964594' }
    //     if (situacao.match(/follow_up/i)) { return '#fff' }
    // }


    const load_tickets = async (_situacao_tickets, show_loading = false) => {
        set_loading_message('Aguarde, carregando lista de chamados.....')

        if (show_loading) {
            set_lista_chamados([])
        }

        const resp = await api_bd(`/tickets/state/${_situacao_tickets}`)
        if (resp.data.length === 0) { set_loading_message('Nenhum chamado encontrado.') }
        // console.log(props.computedMatch.params.idticket)
        // const idticket = window.location.pathname.match(/\/ch\/([0-9]+)/)

        // if (idticket && idticket[1]) {
        //     const chamado = resp.data.filter(c => c.cod_id === parseInt(idticket[1]))
        //     set_navigattion_state({ page_content: 'ticket_detail', data: chamado[0] })
        // }

        // if((lista_chamados.length > 0) && (resp.data.length > lista_chamados.length)){
        //     notifyMe('Novo Chamado',{
        //         body: `Cliente: ${resp.data[resp.data.length-1].customer_name}`,
        //         icon: 'https://sgi.intecsolutions.com.br:4000/files/logo_sgi_intec.png'
        //     })
        // }

        set_lista_chamados(resp.data)
    }


    const load_cards_counter_data = async () => {
        const resp_counter = await api_bd('/tickets/contador')
        // let tmp_list = {}
        // for( let k in resp_counter.data){
        //     let tipo_solic = resp_counter.data[k].situacao.titulo
        //     if(tipo_solic == ''){console.log(resp_counter.data[k].cod_id)}
        //     if(tmp_list[tipo_solic]){
        //         tmp_list[tipo_solic]++
        //     }else{
        //         tmp_list[tipo_solic] = 1
        //     }
        // }
        set_qtd_list_chamados(resp_counter.data)
        //console.log( 'TMP LIST: ', tmp_list,'\n\n') 
    }


    useEffect(() => {
        (async () => {
            await load_tickets(localStorage.getItem('filtro_situacao') || 'em_aberto')
            await load_users()
        })()
        const dw = document.documentElement.clientWidth
        const dh = document.documentElement.clientHeight
        set_device_width(dw)
        set_device_height(dh)
        // console.log('profile', loggedUser)

        // console.log(`initializing interval`);
        const interval = setInterval(async () => {

            await load_cards_counter_data()

            await load_tickets(localStorage.getItem('filtro_situacao') || 'em_aberto')

            // console.log(`atualizando lista`);
        }, 15000);

        return () => {
            //   console.log(`clearing interval`);
            clearInterval(interval);
        };

    }, [])


    const getForMe = async (_id_ticket, _user) => {
        await api_bd.put(`/tickets/getforme/${_id_ticket}`, _user)
        await load_tickets(localStorage.getItem('filtro_situacao') || 'em_aberto')
        set_enabled_getforme(true)
    }

    const RowTickets = () => {

        const dados = lista_chamados.filter((cham) => {
            //console.log((lista_clientes.find(item => item.nome === `${cham.customer_name}`) ? true : false))
            return (
                // ((loggedUser.nivel.match(/Super|Técnico/i)) || (loggedUser.nivel.match(/Cliente/) && cham.customer_id === `${loggedUser.id}`))
                (
                    (loggedUser.nivel.match(/Super|Técnico/i)) ||
                    (loggedUser.nivel === 'Cliente' && cham.customer_id === `${loggedUser.id}`) ||
                    (loggedUser.nivel === 'Cliente-Admin' && (lista_clientes.find(item => item.nome === `${cham.customer_name}`) ? true : false))
                )
                &&
                ((filtro_cliente.length === 0 || filtro_cliente === 'TODOS') ||
                    (cham.customer_name.toLowerCase() === filtro_cliente.toLowerCase())
                )
                &&
                ((filtro_tecnico.length === 0 || filtro_tecnico === 'TODOS') ||
                    (cham.resposaveis_tecnicos.find(el => el.nome.toLowerCase() === filtro_tecnico.toLowerCase() ? true : false))
                )
                &&
                (filtro_pesquisa.length === 0 ||
                    (filtro_pesquisa.match(/^[0-9]+/g) &&
                        (filtro_pesquisa === '0' && cham.cod_id <= 999) ||
                        (filtro_pesquisa === '00' && cham.cod_id <= 99) ||
                        (filtro_pesquisa === '000' && cham.cod_id <= 9) ||
                        (cham.cod_id === parseInt(filtro_pesquisa))
                    ) || (
                        cham.titulo.match(new RegExp(filtro_pesquisa, 'i'))
                    )
                )
                &&
                (
                    (filtro_situacao === 'todos') ||
                    (filtro_situacao.match(/em_aberto|novos_e_atribuidos/i) && cham.situacao.titulo.match(/novo|atribuido/i)) || (
                        (filtro_situacao === cham.situacao.titulo) ||
                        (filtro_situacao === 'em_tratativa' && (cham.situacao.titulo === 'em_tratativa')) ||
                        (filtro_situacao === 'solucionado' && (cham.situacao.titulo === 'solucionado')) ||
                        (filtro_situacao === 'meus' && (cham.resposaveis_tecnicos.find(el => (el.id === loggedUser.id ? true : false))) && (cham.situacao.titulo.match(/atribuido|em_andamento|pendente|aguardando|janela_agendada|em_execucao|em_tratativa|follow_up/i))) ||
                        (filtro_situacao === 'fechado' && (cham.situacao.titulo === 'fechado')) ||
                        (filtro_situacao === 'pendente' && (cham.situacao.titulo.match(/pendente_|aguardando|follow_up/i)))
                    ))
                &&
                (filtro_tipo_solicitacao === 'todos' || (
                    filtro_tipo_solicitacao === cham.tipo_solicitacao ||
                    (filtro_tipo_solicitacao === 'adequacao' && (cham.tipo_solicitacao.match(/adequa/i))) ||
                    (filtro_tipo_solicitacao === 'implantacao' && (cham.tipo_solicitacao.match(/implanta/i))) ||
                    (filtro_tipo_solicitacao === 'migracao' && (cham.tipo_solicitacao.match(/migra/i))) ||
                    (filtro_tipo_solicitacao === 'suporte' && (cham.tipo_solicitacao.match(/suport/i)))
                ))
            )
        })

        const msToTime = (ms) => {
            let x = ms / 1000
            let secs = x % 60
            x /= 60
            let mins = x % 60
            x /= 60
            let hrs = x % 24
            x /= 24
            let days = x

            // console.log(days)

            // return Math.floor(days)-1 + 'd:'  + hrs<10 ? '0'+hrs : hrs + ':' + mins + ':' + secs
            return (`${days >= 1 ? Math.floor(days) + ' dia(s) ' : ''}${hrs < 10 ? '0' + Math.floor(hrs) : Math.floor(hrs)}:${mins < 10 ? '0' + Math.floor(mins) : Math.floor(mins)}:${secs < 10 ? '0' + Math.floor(secs) : Math.floor(secs)}`)
        }

        const calcMs = (_time_inicio, _time_fim) => {
            const date1 = Date.parse(_time_inicio)
            const date2 = Date.parse(_time_fim)
            const diffTime = date2 - date1
            return (diffTime)
        }

        const ContadorTempoTratativas = (props) => {
            let t_execucao = 0
            for (let key in props.dados) {
                if (props.dados[key].fim !== null) {
                    t_execucao += calcMs(props.dados[key].inicio, props.dados[key].fim)
                }
            }

            return (
                <>
                    {props.dados.length === 0 || t_execucao === 0 ? '00:00:00' : ''}
                    {props.dados.length > 0 && t_execucao > 0 && msToTime(t_execucao)}
                </>
            )
        }

        const formatDate = (date_str) => {
            const date = new Date(date_str);  // dateStr you get from mongodb
            const dia = date.getDate();
            const mes = date.getMonth() + 1;
            const ano = date.getFullYear();

            const hora = date.getHours();
            const minuto = date.getMinutes();
            //return {dia, mes, ano, hora, minuto}
            return `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano} às ${hora < 10 ? '0' + hora : hora}:${minuto < 10 ? '0' + minuto : minuto}`
        }

        return (
            <>
                <>
                    {dados.length > 0 &&
                        dados.map((chamado, i, array) => {
                            if (list_of_reverse_ID) {
                                chamado = array[array.length - 1 - i]
                            }

                            const meuTicket = chamado.tratativas.filter(tratativa => {
                                if (tratativa.id_usuario === loggedUser.id && tratativa.fim === null) {
                                    return tratativa
                                } else {
                                    return false
                                }
                            })

                            const tecnicosAtivos = chamado.tratativas.filter(tratativa => {
                                if (tratativa.inicio !== null && tratativa.fim === null) {
                                    return tratativa
                                } else {
                                    return false
                                }
                            })



                            return (
                                <TableRow key={chamado._id}>
                                    {dados.length === i + 1 ?
                                        <TableCell style={{ border: '2px solid #fff', borderBottomLeftRadius: 10 }} align="center">{chamado.cod_id < 10 ? '000' + chamado.cod_id : chamado.cod_id < 100 ? '00' + chamado.cod_id : chamado.cod_id < 1000 ? '0' + chamado.cod_id : chamado.cod_id}</TableCell>
                                        :
                                        <TableCell style={{ border: '2px solid #fff', borderRadius: 5 }} align="center">{chamado.cod_id < 10 ? '000' + chamado.cod_id : chamado.cod_id < 100 ? '00' + chamado.cod_id : chamado.cod_id < 1000 ? '0' + chamado.cod_id : chamado.cod_id}</TableCell>
                                    }
                                    <TableCell style={{ border: '2px solid #fff', borderRadius: 5 }} align="left" onClick={() => {
                                        set_navigattion_state({ page_content: 'ticket_detail', data: chamado })
                                    }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                {chamado?.anexo?.length > 0 &&
                                                    <span style={{ fontSize: '1.3em', marginLeft: 0, marginTop: 0 }} className="material-icons">attach_file</span>
                                                }<div style={{ fontSize: '1em' }}> {chamado.titulo}</div>
                                            </div>
                                            {meuTicket.length > 0 &&
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.8em', color: 'grey', paddingTop: 5 }}>
                                                    Atendimento iniciado em: {formatDate(meuTicket[0].inicio)}
                                                </div>
                                            }

                                            {loggedUser.nivel.match(/Super|Técnico-Admin/i) && tecnicosAtivos.length > 0 &&
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.8em', color: 'grey', paddingTop: 5 }}>
                                                    Em execução por: {tecnicosAtivos.map((tecnico, i) => (`${tecnico.nome}${tecnicosAtivos[i + 1] ? ', ' : ''}`))}
                                                </div>
                                            }

                                            {chamado.situacao.titulo === 'follow_up' &&
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.8em', color: 'grey', paddingTop: 5 }}>
                                                    <span style={{ fontSize: '1em', marginLeft: 0 }}>Este chamado voltará para o status <b style={{ fontSize: '1em' }}> "Em Tratativa" </b> em: <b style={{ fontSize: '1em' }}>{new Date(chamado.followup.reopen_date).toLocaleDateString()}</b></span>
                                                </div>
                                            }
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ border: '2px solid #fff', borderRadius: 5, fontSize: '1em' }} align="left">
                                        {chamado.customer_name}<br />
                                        {/* {chamado.nome}<br /> */}
                                        {/* <span style={{ marginLeft: 0, fontSize: '0.8em' }}>
                                        ({chamado.customer_name})
                                        </span> */}
                                    </TableCell>
                                    <TableCell style={{ border: '2px solid #fff', borderRadius: 5 }} align="left">
                                        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: 70, maxWidth: 70 }}>
                                            <div style={{ display: 'flex', marginTop: -20, marginBottom: -20, marginLeft: -12, marginRight: 5, minHeight: 60, minWidth: 6, width: 6, backgroundColor: cor_tipos_situacao[chamado.situacao.titulo] }}></div>
                                            <div style={{ width: '100%', fontSize: '1em' }}>
                                                {tipos_situacao[chamado.situacao.titulo]}
                                            </div>
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ border: '2px solid #fff', borderRadius: 5 }} align="left">
                                        <div style={{ marginTop: -4, display: 'flex', flexDirection: 'row' }}>
                                            <span
                                                style={{ margin: 2, fontSize: '1.1em', color: chamado.prioridade === 'padrão' ? "#888888" : chamado.prioridade === 'baixa' ? "#228B22" : chamado.prioridade === 'média' ? "#ff7900" : "#ff0000" }}
                                                className="material-icons">warning</span>

                                            <span style={{ margin: 2, fontSize: '1.1em', color: chamado.prioridade === 'média' ? "#ff7900" : chamado.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                                className="material-icons">warning</span>

                                            <span
                                                style={{ margin: 2, fontSize: '1.1em', color: chamado.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                                className="material-icons">warning</span>
                                        </div>
                                    </TableCell>
                                    <TableCell style={{ border: '2px solid #fff', borderRadius: 5, textAlign: 'center' }}>
                                        {formatDate(chamado.createdAt)}
                                    </TableCell>
                                    <TableCell style={{ border: '2px solid #fff', borderRadius: 5 }} align="left">
                                        {chamado.resposaveis_tecnicos.length === 0
                                            ?
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                {/* {(loggedUser.nivel.match(/Super|Técnico/i)) && (!chamado.situacao.titulo.match(/fechado|solucionado|encerrado_inatividade/i)) &&
                                                    <span style={{ cursor: 'pointer', marginLeft: 0, marginRight: 5, fontSize: '1.3em' }} className="material-icons" onClick={() => {
                                                        if (enabled_getforme) {
                                                            getForMe(chamado._id, loggedUser)
                                                        }
                                                        set_enabled_getforme(false)
                                                    }}>person_add_alt</span>
                                                } */}
                                                <div style={{ fontSize: '1em' }}>Sem responsável técinco</div>
                                            </div>
                                            :
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <>
                                                    {(() => {
                                                        let show_add = true
                                                        if (chamado.resposaveis_tecnicos.find(el => (el.id === loggedUser.id ? true : false))) { show_add = false }
                                                        return (show_add && (loggedUser.nivel.match(/Super|Técnico/i)) && (!chamado.situacao.titulo.match(/fechado|solucionado|encerrado_inatividade/i)) && <span style={{ cursor: 'pointer', marginLeft: 0, marginRight: 5, fontSize: '1.3em' }} className="material-icons" onClick={() => getForMe(chamado._id, loggedUser)}>person_add_alt</span>)
                                                    })()}
                                                    {(() => {
                                                        const qtd_tec = chamado.resposaveis_tecnicos.length
                                                        return qtd_tec < 3
                                                            ?
                                                            <span style={{ marginLeft: 2, fontSize: '1em' }}>{chamado.resposaveis_tecnicos.map(tech => (tech.nome)).join(', ')}</span>
                                                            :
                                                            <span style={{ marginLeft: 2, fontSize: '1em' }}>{chamado.resposaveis_tecnicos.map(tech => (tech.nome.split(' ')[0])).join(', ')}</span>

                                                    })()}
                                                </>
                                            </div>
                                        }
                                    </TableCell>
                                    <TableCell style={{ border: '2px solid #fff' }} align="left">
                                        <ContadorTempoTratativas dados={chamado.tratativas} />
                                    </TableCell>
                                    {dados.length === i + 1 ?
                                        <TableCell style={{ border: '2px solid #fff', borderBottomRightRadius: 10 }} align="left">
                                            {formatDate(chamado.updatedAt)}
                                        </TableCell>
                                        :
                                        <TableCell style={{ border: '2px solid #fff', borderRadius: 5 }} align="left">
                                            {formatDate(chamado.updatedAt)}
                                        </TableCell>
                                    }

                                </TableRow>
                            )
                        })
                    }
                </>
                <>
                    {dados.length === 0 &&
                        <TableRow>
                            <TableCell colSpan={9} style={{ border: '2px solid #fff', borderBottomLefTableRowadius: 10, borderBottomRightRadius: 10, textAlign: 'center' }}>
                                <span style={{ fontSize: '1em' }}>Nenhuma informação encontrada para os filtros selecionados</span>
                            </TableCell>
                        </TableRow>
                    }
                </>
            </>
        )
    }

    const RowTicketsMin = () => {

        const dados = lista_chamados.filter((cham) => {
            // console.log(cham)  filtro_tecnico
            return (
                // ((loggedUser.nivel.match(/Super|Técnico/i)) || (loggedUser.nivel.match(/Cliente/) && cham.customer_id === `${loggedUser.id}`))
                (
                    (loggedUser.nivel.match(/Super|Técnico/i)) ||
                    (loggedUser.nivel === 'Cliente' && cham.customer_id === `${loggedUser.id}`) ||
                    (loggedUser.nivel === 'Cliente-Admin' && (lista_clientes.find(item => item.nome === `${cham.customer_name}`) ? true : false))
                )
                &&
                ((filtro_cliente.length === 0 || filtro_cliente === 'TODOS') ||
                    (cham.customer_name.toLowerCase() === filtro_cliente.toLowerCase())
                )
                &&
                ((filtro_tecnico.length === 0 || filtro_tecnico === 'TODOS') ||
                    (cham.resposaveis_tecnicos.find(el => el.nome.toLowerCase() === filtro_tecnico.toLowerCase() ? true : false))
                )
                &&
                (filtro_pesquisa.length === 0 ||
                    (filtro_pesquisa.match(/^[0-9]+/g) &&
                        (filtro_pesquisa === '0' && cham.cod_id <= 999) ||
                        (filtro_pesquisa === '00' && cham.cod_id <= 99) ||
                        (filtro_pesquisa === '000' && cham.cod_id <= 9) ||
                        (cham.cod_id === parseInt(filtro_pesquisa))
                    ) || (
                        cham.titulo.match(new RegExp(filtro_pesquisa, 'i'))
                    )
                )
                &&
                (
                    (filtro_situacao === 'todos') ||
                    (filtro_situacao.match(/em_aberto|novos_e_atribuidos/i) && cham.situacao.titulo.match(/novo|atribuido/i)) || (
                        (filtro_situacao === cham.situacao.titulo) ||
                        (filtro_situacao === 'em_tratativa' && (cham.situacao.titulo === 'em_tratativa')) ||
                        (filtro_situacao === 'solucionado' && (cham.situacao.titulo === 'solucionado')) ||
                        (filtro_situacao === 'meus' && (cham.resposaveis_tecnicos.find(el => (el.id === loggedUser.id ? true : false))) && (cham.situacao.titulo.match(/atribuido|em_andamento|pendente_|aguardando|janela_agendada|em_execucao|em_tratativa|follow_up/i))) ||
                        (filtro_situacao === 'fechado' && (cham.situacao.titulo === 'fechado')) ||
                        (filtro_situacao === 'pendente' && (cham.situacao.titulo.match(/pendente_|aguardando|follow_up/i)))
                    ))
                &&
                (filtro_tipo_solicitacao === 'todos' || (
                    filtro_tipo_solicitacao === cham.tipo_solicitacao ||
                    (filtro_tipo_solicitacao === 'suporte' && (cham.tipo_solicitacao === 'servico_cliente_provedor' || cham.tipo_solicitacao === 'suporte_provedor'))
                ))
            )
        })

        const msToTime = (ms) => {
            let x = ms / 1000
            let secs = x % 60
            x /= 60
            let mins = x % 60
            x /= 60
            let hrs = x % 24
            x /= 24
            let days = x

            // console.log(days)

            // return Math.floor(days)-1 + 'd:'  + hrs<10 ? '0'+hrs : hrs + ':' + mins + ':' + secs
            return (`${days >= 1 ? Math.floor(days) + ' dia(s) ' : ''}${hrs < 10 ? '0' + Math.floor(hrs) : Math.floor(hrs)}:${mins < 10 ? '0' + Math.floor(mins) : Math.floor(mins)}:${secs < 10 ? '0' + Math.floor(secs) : Math.floor(secs)}`)
        }

        const calcMs = (_time_inicio, _time_fim) => {
            const date1 = Date.parse(_time_inicio)
            const date2 = Date.parse(_time_fim)
            const diffTime = date2 - date1
            return (diffTime)
        }

        const ContadorTempoTratativas = (props) => {
            let t_execucao = 0
            for (let key in props.dados) {
                if (props.dados[key].fim !== null) {
                    t_execucao += calcMs(props.dados[key].inicio, props.dados[key].fim)
                }
            }

            return (
                <>
                    {props.dados.length === 0 || t_execucao === 0 ? '00:00:00' : ''}
                    {props.dados.length > 0 && t_execucao > 0 && msToTime(t_execucao)}
                </>
            )
        }

        const formatDate = (date_str) => {
            const date = new Date(date_str);  // dateStr you get from mongodb
            const dia = date.getDate();
            const mes = date.getMonth() + 1;
            const ano = date.getFullYear();

            const hora = date.getHours();
            const minuto = date.getMinutes();
            //return {dia, mes, ano, hora, minuto}
            return `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano} às ${hora < 10 ? '0' + hora : hora}:${minuto < 10 ? '0' + minuto : minuto}`
        }

        return (
            <>
                {dados.length > 0 &&
                    dados.map((chamado, i, array) => {
                        if (list_of_reverse_ID) {
                            chamado = array[array.length - 1 - i]
                        }

                        const meuTicket = chamado.tratativas.filter(tratativa => {
                            if (tratativa.id_usuario === loggedUser.id && tratativa.fim === null) {
                                return tratativa
                            } else {
                                return false
                            }
                        })

                        const tecnicosAtivos = chamado.tratativas.filter(tratativa => {
                            if (tratativa.inicio !== null && tratativa.fim === null) {
                                return tratativa
                            } else {
                                return false
                            }
                        })



                        return (
                            <TableRow key={chamado._id}>
                                <TableCell
                                    style={{ cursor: 'pointer', padding: 5, border: '2px solid #fff', width: 50 }}
                                    onClick={() => {
                                        set_navigattion_state({ page_content: 'ticket_detail', data: chamado })
                                    }
                                    }
                                >   <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 3, borderBottom: '1px solid #c3c3c3' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>ID:</div>
                                        <div style={{ width: '70%' }}>{chamado.cod_id < 10 ? '000' + chamado.cod_id : chamado.cod_id < 100 ? '00' + chamado.cod_id : chamado.cod_id < 1000 ? '0' + chamado.cod_id : chamado.cod_id}</div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 3, borderBottom: '1px solid #c3c3c3' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>Titulo</div>
                                        <div style={{ width: '70%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column', cursor: 'pointer', alignItems: 'center' }}>
                                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
                                                    {chamado?.anexo?.length > 0 &&
                                                        <span style={{ fontSize: '1.3em', marginLeft: 0, marginTop: 0 }} className="material-icons">attach_file</span>
                                                    }<div style={{ fontSize: '1em' }}> {chamado.titulo}</div>
                                                </div>
                                                {meuTicket.length > 0 &&
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.8em', color: 'grey', paddingTop: 5 }}>
                                                        Atendimento iniciado em: {formatDate(meuTicket[0].inicio)}
                                                    </div>
                                                }

                                                {loggedUser.nivel.match(/Super|Técnico-Admin/i) && tecnicosAtivos.length > 0 &&
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.8em', color: 'grey', paddingTop: 5 }}>
                                                        Em tratativa por: {tecnicosAtivos.map((tecnico, i) => (`${tecnico.nome}${tecnicosAtivos[i + 1] ? ', ' : ''}`))}
                                                    </div>
                                                }

                                                {chamado.situacao.titulo === 'follow_up' &&
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', fontSize: '0.8em', color: 'grey', paddingTop: 5 }}>
                                                        <span style={{ fontSize: '1em', marginLeft: 0 }}>Este chamado voltará para o status <b style={{ fontSize: '1em' }}> "Em Tratativa" </b> em: <b style={{ fontSize: '1em' }}>{new Date(chamado.followup.reopen_date).toLocaleDateString()}</b></span>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 3, borderBottom: '1px solid #c3c3c3' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>Requerente:</div>
                                        <div style={{ width: '70%' }}>
                                            {chamado.customer_name}<br />
                                            {/* {chamado.nome}<br /> */}
                                            {/* <span style={{ marginLeft: 0, fontSize: '0.8em' }}>
                                            ({chamado.customer_name})
                                            </span> */}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 3, borderBottom: '1px solid #c3c3c3' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>Situação:</div>
                                        <div style={{ width: '70%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'flex-start' }}>
                                                <div style={{ display: 'flex', flexDirection: 'column', flex: '0 1 auto', height: 15, marginLeft: -12, marginRight: 5, minWidth: 6, width: 6, backgroundColor: cor_tipos_situacao[chamado.situacao.titulo] }}></div>
                                                <div style={{ width: '100%', fontSize: '1em', display: 'flex', justifyContent: 'flex-start' }}>
                                                    {tipos_situacao[chamado.situacao.titulo]}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 3, borderBottom: '1px solid #c3c3c3' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>Pioridade:</div>
                                        <div style={{ width: '70%' }}>
                                            <div style={{ marginTop: -4, display: 'flex', flexDirection: 'row' }}>
                                                <span
                                                    style={{ margin: 2, fontSize: '1.1em', color: chamado.prioridade === 'padrão' ? "#888888" : chamado.prioridade === 'baixa' ? "#228B22" : chamado.prioridade === 'média' ? "#ff7900" : "#ff0000" }}
                                                    className="material-icons">warning</span>

                                                <span style={{ margin: 2, fontSize: '1.1em', color: chamado.prioridade === 'média' ? "#ff7900" : chamado.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                                    className="material-icons">warning</span>

                                                <span
                                                    style={{ margin: 2, fontSize: '1.1em', color: chamado.prioridade === 'alta' ? "#ff0000" : "#888888" }}
                                                    className="material-icons">warning</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 3, borderBottom: '1px solid #c3c3c3' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>Data de Abertura:</div>
                                        <div style={{ width: '70%' }}>
                                            {formatDate(chamado.createdAt)}
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 3, borderBottom: '1px solid #c3c3c3' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>Técnico:</div>
                                        <div style={{ width: '70%' }}>
                                            {chamado.resposaveis_tecnicos.length === 0
                                                ?
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {/* {(loggedUser.nivel.match(/Super|Técnico/i)) && (!chamado.situacao.titulo.match(/fechado|solucionado|encerrado_inatividade/i)) &&
                                                        <span style={{ cursor: 'pointer', marginLeft: 0, marginRight: 5, fontSize: '1.3em' }} className="material-icons" onClick={() => {
                                                            if (enabled_getforme) {
                                                                getForMe(chamado._id, loggedUser)
                                                            }
                                                            set_enabled_getforme(false)
                                                        }}>person_add_alt</span>
                                                    } */}
                                                    <div style={{ fontSize: '1em' }}>Sem responsável técinco</div>
                                                </div>
                                                :
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <>
                                                        {(() => {
                                                            let show_add = true
                                                            if (chamado.resposaveis_tecnicos.find(el => (el.id === loggedUser.id ? true : false))) { show_add = false }
                                                            return (show_add && (loggedUser.nivel.match(/Super|Técnico/i)) && (!chamado.situacao.titulo.match(/fechado|solucionado|encerrado_inatividade/i)) && <span style={{ cursor: 'pointer', marginLeft: 0, marginRight: 5, fontSize: '1.3em' }} className="material-icons" onClick={() => getForMe(chamado._id, loggedUser)}>person_add_alt</span>)
                                                        })()}
                                                        {(() => {
                                                            const qtd_tec = chamado.resposaveis_tecnicos.length
                                                            return qtd_tec < 3
                                                                ?
                                                                <span style={{ marginLeft: 2, fontSize: '1em' }}>{chamado.resposaveis_tecnicos.map(tech => (tech.nome)).join(', ')}</span>
                                                                :
                                                                <span style={{ marginLeft: 2, fontSize: '1em' }}>{chamado.resposaveis_tecnicos.map(tech => (tech.nome.split(' ')[0])).join(', ')}</span>

                                                        })()}
                                                    </>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 3, borderBottom: '1px solid #c3c3c3' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>Tempo de Execução:</div>
                                        <div style={{ width: '70%' }}>
                                            <ContadorTempoTratativas dados={chamado.tratativas} />
                                        </div>
                                    </div>

                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <div style={{ width: '30%', minWidth: 80 }}>Última Atualização:</div>
                                        <div style={{ width: '70%' }}>
                                            {formatDate(chamado.updatedAt)}
                                        </div>
                                    </div>

                                </TableCell>
                            </TableRow>
                        )
                    })
                }

                {dados.length === 0 &&
                    <TableRow>
                        <TableCell colSpan={9} style={{ border: '2px solid #fff', borderBottomLefTableRowadius: 10, borderBottomRightRadius: 10, textAlign: 'center' }}>
                            <span style={{ fontSize: '1em' }}>Nenhuma informação encontrada para os filtros selecionados</span>
                        </TableCell>
                    </TableRow>
                }

            </>
        )
    }



    const Select_Custom = ({ callbackfn, value, _style_text = {}, _style_item_list = {}, dados }) => {
        const list = [{ nome: 'TODOS' }, ...dados]
        const [visible, set_visible] = useState(window.sgi_custom_select || false)
        // const [selected, set_selected] = useState(false)

        return (
            <div style={{ display: 'flex', flexDirection: 'row', minWidth: 150 }}>
                <div
                    style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer', alignItems: 'center', ..._style_text }}
                    onClick={() => {
                        window.sgi_custom_select = window.sgi_custom_select === undefined ? true : !window.sgi_custom_select
                        // console.log(window.sgi_custom_select)
                        set_visible(!visible)
                    }}>
                    <div
                        style={{ minWidth: 150, marginLeft: 2, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}
                    >
                        {value}<span className="material-icons">{`${visible ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}`}</span>
                    </div>
                </div>

                {visible &&
                    <div
                        style={{ position: 'absolute', minWidth: 160, backgroundColor: '#fff', borderRadius: 5, padding: 10, marginTop: 20, marginLeft: -15, maxHeight: '50vh', overflowY: 'auto' }}
                    >
                        {list.map(el => (
                            <div
                                key={el._id}
                                style={{ marginTop: 5, marginBottom: 5, padding: 5, ..._style_item_list }}
                                onMouseOver={(e) => { e.target.style.backgroundColor = '#3c3c3c11' }}
                                onMouseLeave={(e) => { e.target.style.backgroundColor = '#fff' }}
                                onClick={() => {
                                    window.sgi_custom_select = false
                                    callbackfn(el)
                                    // set_selected(e.target.innerText)
                                    set_visible(false)
                                }}
                            >{el.nome}</div>
                        ))}
                    </div>
                }
            </div>
        )
    }


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            flex: 1,
            left: 0,
            top: 0,
            maxWidth: '100%',
            padding: 5,
            justifyContent: 'center',
            alignItems: 'flex-start',
            overflowY: "auto"
        }}
            className="height_less_header">
            <div className={styles.div_root_component} style={{ width: '100%' }}>
                <div style={{ height: 30, marginLeft: 2, marginRight: 2, marginTop: 20, display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alingItems: 'flex-start' }}>
                    <div style={{ fontSize: '1.5em', fontWeight: 'bold', marginTop: 6 }}>
                        CHAMADOS
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', fontSize: '1em', fontWeight: 'bold', marginLeft: 2, marginTop: 20, justifyContent: 'center' }}>
                    {loggedUser.nivel.match(/Super|Técnico/i) &&
                        <CardsContadores style={{ maxWidth: '100%', minWidth: '100%' }} set_filtro_situacao={set_filtro_situacao} load_tickets={load_tickets} />
                    }

                    <div style={{ maxWidth: '94%', minWidth: '94%', display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', paddingTop: 40 }}> {/*DIV container FILTROS*/}

                        <div style={{ borderRadius: 5, paddingLeft: 15, paddingBottom: 13, marginTop: 10, display: 'flex', minWidth: '75%', justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', backgroundColor: 'rgb(227,227,227)' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 50 }}>
                                <div style={{ fontSize: '1em', marginTop: 14, paddingBottom: 6, paddingRight: 8, borderBottom: '1px solid #0007' }}>FILTRO:</div>
                                <div style={{ fontSize: '1em', marginLeft: 0, marginTop: 9 }}>
                                    <>
                                        <NivelDeAcesso visivel_para={['Técnico', 'Técnico-Admin', 'Super']}>
                                            <Select
                                                style={{ width: 130, fontSize: '0.9em', border: 'none' }}
                                                value={filtro_situacao}
                                                labelId="select-tipo-solicitacao"
                                                onChange={e => {
                                                    localStorage.setItem('filtro_situacao', e.target.value)
                                                    set_filtro_situacao(e.target.value)
                                                    load_tickets(e.target.value, true)
                                                }}
                                            >
                                                <MenuItem value="em_aberto">EM ABERTO</MenuItem>
                                                <MenuItem value="em_execucao">EM EXECUÇÃO</MenuItem>
                                                <MenuItem value="em_tratativa">EM TRATATIVA</MenuItem>
                                                <MenuItem value="novos_e_atribuidos">NOVOS E ATRIBUÍDOS</MenuItem>

                                                <MenuItem value="meus">MEUS</MenuItem>

                                                <MenuItem value="em_andamento">EM ANDAMENTO</MenuItem>
                                                <MenuItem value="novo">NOVO</MenuItem>
                                                <MenuItem value="pendente">PENDENTE</MenuItem>

                                                <MenuItem value="Aguardando aprovação">AGUARDANDO APROVAÇÃO</MenuItem>
                                                <MenuItem value="Aguardando auditoria">AGUARDANDO AUDITORIA</MenuItem>

                                                <MenuItem value="janela_agendada">JANELA AGENDADA</MenuItem>
                                                <MenuItem value="solucionado">SOLUCIONADO</MenuItem>
                                                <MenuItem value="fechado">FECHADO</MenuItem>
                                                <MenuItem value="follow_up">FOLLOW-UP</MenuItem>
                                                <MenuItem value="todos">TODOS</MenuItem>

                                            </Select>
                                        </NivelDeAcesso>

                                        <NivelDeAcesso visivel_para={['Cliente', 'Cliente-Admin']}>
                                            <Select
                                                style={{ width: 130, fontSize: '0.9em' }}
                                                value={filtro_situacao}
                                                labelId="select-tipo-solicitacao"
                                                onChange={e => {
                                                    localStorage.setItem('filtro_situacao', e.target.value)
                                                    set_filtro_situacao(e.target.value)
                                                }}
                                            >
                                                <MenuItem value="em_aberto">EM ABERTO</MenuItem>
                                                <MenuItem value="em_execucao">EM EXECUÇÃO</MenuItem>
                                                <MenuItem value="em_tratativa">EM TRATATIVA</MenuItem>
                                                <MenuItem value="novos_e_atribuidos">NOVOS E ATRIBUÍDOS</MenuItem>
                                                <MenuItem value="em_andamento">EM ANDAMENTO</MenuItem>
                                                <MenuItem value="novo">NOVO</MenuItem>
                                                <MenuItem value="pendente">PENDENTE</MenuItem>

                                                <MenuItem value="Aguardando aprovação">AGUARDANDO APROVAÇÃO</MenuItem>
                                                <MenuItem value="Aguardando auditoria">AGUARDANDO AUDITORIA</MenuItem>

                                                <MenuItem value="janela_agendada">JANELA AGENDADA</MenuItem>
                                                <MenuItem value="solucionado">SOLUCIONADO</MenuItem>
                                                <MenuItem value="fechado">FECHADO</MenuItem>
                                                <MenuItem value="follow_up">FOLLOW-UP</MenuItem>
                                                <MenuItem value="todos">TODOS</MenuItem>
                                            </Select>
                                        </NivelDeAcesso>
                                    </>
                                </div>
                            </div>

                            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 50 }}>
                                <div style={{ fontSize: '1em', marginTop: 14, paddingBottom: 6, paddingRight: 5, borderBottom: '1px solid #0007' }}>CATEGORIA:</div>
                                <div style={{ fontSize: '1em', marginLeft: 0, marginTop: 9 }}>
                                    <Select
                                        style={{ width: 150, fontSize: '0.9em' }}
                                        value={filtro_tipo_solicitacao}
                                        labelId="select-tipo-solicitacao"
                                        onChange={e => {
                                            // console.log(e.target.value)
                                            localStorage.setItem('filtro_tipo_solicitacao', e.target.value)
                                            set_filtro_tipo_solicitacao(e.target.value)
                                        }}
                                    >
                                        <MenuItem value="todos">TODOS</MenuItem>
                                        {/* <MenuItem value="adequacao">ADEQUAÇÃO</MenuItem>
                                        <MenuItem value="implantacao">IMPLANTAÇÃO</MenuItem>
                                        <MenuItem value="migracao">MIGRAÇÃO</MenuItem>
                                        <MenuItem value="suporte">SUPORTE</MenuItem> */}
                                        <MenuItem value="NOC">NOC</MenuItem>
                                        <MenuItem value="CO">CO</MenuItem>
                                        <MenuItem value="ELITE">ELITE</MenuItem>
                                        <MenuItem value="CHAMADOS INTERNOS">CHAMADOS INTERNOS</MenuItem>
                                    </Select>
                                </div>
                            </div>


                            
                            {/* {(loggedUser.nivel.match(/Super|Técnico/i)) &&
                                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 50, alignItems: 'center' }}>
                                    <div style={{ fontSize: '1em', marginTop: 15, paddingBottom: 5, paddingRight: 8, borderBottom: '1px solid #0007' }}>CLIENTE:</div>

                                    <div style={{ fontSize: '1em', marginLeft: 0, marginTop: 1 }}>
                                        <Autocomplete
                                            // defaultValue={{nome:'TODOS'}}
                                            value={{ nome: filtro_cliente }}
                                            style={{ width: 150, fontSize: '1em' }}
                                            options={[{ nome: 'TODOS' }, ...lista_clientes]}
                                            getOptionLabel={(option) => `${option.nome}`}
                                            id="cliente"
                                            autoComplete
                                            classes={{ inputRoot: styles.font_09 }}
                                            includeInputInList
                                            renderInput={(params) => <TextField {...params} label={props.label ? props.label : ''} margin="normal" />}
                                            onChange={e => {
                                                localStorage.setItem('filtro_cliente', e.target.innerText ? e.target.innerText : 'TODOS')
                                                set_filtro_cliente(e.target.innerText ? e.target.innerText : 'TODOS')
                                                // console.log('Valor Filtro: ', e.target.innerText)
                                                // props.returnData ? props.returnData(e.target.innerText ? e.target.innerText : "") : console.log('Retorno: ', e.target.innerText ? e.target.innerText : "")
                                            }}
                                        />
                                    </div>
                                </div>
                            } */}


                            {(loggedUser.nivel.match(/Super|Técnico-Admin/i)) &&
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginRight: 50 }}>
                                    <div style={{ fontSize: '1em', marginTop: 15, paddingBottom: 5, paddingRight: 8, borderBottom: '1px solid #0007' }}>TÉCNICO:</div>
                                    <div style={{ fontSize: '1em', marginLeft: 0, marginTop: 1 }}>
                                        <Autocomplete
                                            // defaultValue={{nome:'TODOS'}}
                                            value={{ nome: filtro_tecnico }}
                                            style={{ width: 150, fontSize: '1em' }}
                                            options={[{ nome: 'TODOS' }, ...list_users]}
                                            getOptionLabel={(option) => `${option.nome}`}
                                            id="tecnico"
                                            autoComplete
                                            classes={{ inputRoot: styles.font_09 }}
                                            includeInputInList
                                            renderInput={(params) => <TextField {...params} label={props.label ? props.label : ''} margin="normal" />}
                                            onChange={e => {
                                                localStorage.setItem('filtro_tecnico', e.target.innerText ? e.target.innerText : 'TODOS')
                                                set_filtro_tecnico(e.target.innerText ? e.target.innerText : 'TODOS')
                                                // console.log('Valor Filtro: ', e.target.innerText)
                                                // props.returnData ? props.returnData(e.target.innerText ? e.target.innerText : "") : console.log('Retorno: ', e.target.innerText ? e.target.innerText : "")
                                            }}
                                        />
                                    </div>
                                </div>
                            }
                        </div>

                        <div className="div_divider_h_2_percent"></div>

                        <div style={{ borderRadius: 5, paddingLeft: 15, paddingBottom: 13, marginTop: 10, minWidth: 260, display: 'flex', minWidth: '23%', flex: 1, justifyContent: 'space-between', alignItems: 'center', flexWrap: 'wrap', backgroundColor: 'rgb(227,227,227)' }}>
                            {/* Pesquisa por ID ou Titulo do ticket */}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 16 }}>
                                    <span style={{ margin: 0 }} className="material-icons">search</span>
                                </div>
                                <div style={{ marginLeft: 3, marginTop: -3 }}>
                                    <TextField
                                        style={{ fontSize: "0.8em", width: 200 }}
                                        onChange={(e) => {
                                            localStorage.setItem('filtro_pesquisa', e.target.value)
                                            set_filtro_pesquisa(e.target.value)

                                            if (filtro_situacao !== 'todos') {
                                                localStorage.setItem('filtro_situacao', 'todos')
                                                set_filtro_situacao('todos')
                                                load_tickets('todos', true)
                                            }
                                            if (filtro_tipo_solicitacao !== 'todos') {
                                                localStorage.setItem('filtro_tipo_solicitacao', 'todos')
                                                set_filtro_tipo_solicitacao('todos')
                                            }
                                            if (filtro_cliente !== '') {
                                                localStorage.setItem('filtro_tipo_solicitacao', 'todos')
                                                set_filtro_tipo_solicitacao('todos')
                                            }
                                        }}
                                        size="small"
                                        label="Filtrar por ID ou Título"
                                        value={filtro_pesquisa}
                                    />
                                </div>
                            </div>
                        </div>

                        {/* {loggedUser.nome.match(/monitoramento/i) &&
                            <div style={{ display: 'flex', flexDirection: 'row', marginRight: 50 }}>
                                <div style={{ fontSize: '1em', marginTop: 20, minWidth: 60 }}>MODO TV</div>
                                <div style={{ fontSize: '1em', marginLeft: 0, marginTop: 16 }}>
                                    <Select
                                        style={{ width: 150, fontSize: '0.9em' }}
                                        value={modo_monitor === true ? 'sim' : 'nao'}
                                        labelId="select-modo-monitoramento"
                                        onChange={e => {
                                            set_modo_monitor(e.target.value === 'sim' ? true : false)
                                        }}
                                    >
                                        <MenuItem value="sim">SIM</MenuItem>
                                        <MenuItem value="nao">NÃO</MenuItem>
                                    </Select>
                                </div>
                            </div>
                        } */}

                    </div>{/* FIM DIV container filtros Filtros */}


                </div>
                {lista_chamados.length > 0 &&
                    <>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                            <TableContainer component={Paper} style={{ marginTop: 20, minWidth: '94%', maxWidth: '94%' }} className='ticketsTableFull'>
                                <Table style={{ border: '1px solid #fff' }}>
                                    <TableHead style={{ backgroundColor: '#3c5ca6', borderRadius: 5 }}>
                                        <TableRow>
                                            <TableCell
                                                onClick={() => set_list_of_reverse_ID(!list_of_reverse_ID)}
                                                style={{ cursor: 'pointer', padding: 5, color: '#fff', border: '2px solid #fff', width: 50, borderTopLeftRadius: 10 }}
                                                align="center"
                                            >#</TableCell>
                                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff' }} align="center">Título</TableCell>
                                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 120 }} align="center">Requerente</TableCell>
                                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 80 }} align="center">Situação</TableCell>
                                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 80 }} align="center">Prioridade</TableCell>
                                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 150 }} align="center">Data de Abertura</TableCell>
                                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 150 }} align="center">Técnico</TableCell>
                                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 100 }} align="center">Tempo de Execução</TableCell>
                                            <TableCell style={{ padding: 5, color: '#fff', border: '2px solid #fff', width: 100, borderTopRightRadius: 10 }} align="center">Última Atualização</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody style={{ backgroundColor: '#e3e3e3', borderRadius: 5 }}>

                                        <RowTickets />

                                    </TableBody>
                                </Table>
                            </TableContainer>

                            <TableContainer component={Paper} style={{ marginTop: 20, minWidth: '94%', maxWidth: '94%' }} className='ticketsTableMin'>
                                <Table style={{ border: '1px solid #fff' }}>
                                    <TableBody style={{ backgroundColor: '#e3e3e3', borderRadius: 5 }}>
                                        <TableRow style={{ backgroundColor: '#3c5ca6' }}>
                                            <TableCell
                                                onClick={() => set_list_of_reverse_ID(!list_of_reverse_ID)}
                                                style={{ cursor: 'pointer', padding: 5, color: '#fff', border: '2px solid #fff', width: 50, borderTopLeftRadius: 10, borderTopRightRadius: 10 }}
                                                align="center"
                                            >
                                                <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
                                                    {`${list_of_reverse_ID ? 'Ordem decrescente' : 'Ordem crescente'}`}<span style={{ fontSize: '1.2em' }} className="material-icons">{`${list_of_reverse_ID ? 'arrow_upward' : 'arrow_downward'}`}</span>
                                                </div>
                                            </TableCell>
                                        </TableRow>

                                        <RowTicketsMin />


                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </div>
                    </>
                }

                {lista_chamados.length === 0 &&
                    <div style={{ maxWidth: '100%', minWidth: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Card className={styles.card} style={{ maxWidth: '94%', minWidth: '94%' }} >
                            <CardContent style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }}>
                                <span style={{ fontSize: '1.3em' }}>{loading_message}</span>
                            </CardContent>
                        </Card>
                    </div>
                }
            </div>
        </div>
    );
}

export default TableChamados;